<template>
  <v-container fluid class="mr-2 ml-2">
    <v-tabs v-model="tab" @change="onTabChange">
      <v-tab>Medicamento</v-tab>
      <v-tab>Equipamento</v-tab>
      <v-tab>Esterilizáveis</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Medicamento ref="equipamentoTab" :paciente="paciente" />
      </v-tab-item>
      <v-tab-item>
        <Equipamento ref="equipamentoTab" :paciente="paciente" />
      </v-tab-item>
      <v-tab-item>
        <Esterilizavel ref="esterilizavelTab" :paciente="paciente" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Equipamento from "./equipamento/Index.vue";
import Esterilizavel from "./esterilizaveis/Index.vue";
import Medicamento from "./medicamento/Index.vue"

export default {
  name: "Prescricoes",
  props: {
    paciente: {
      type: Object,
      required: true,
    },
  },
  components: {
    Equipamento,
    Esterilizavel,
    Medicamento,
  },
  data() {
    return {
      tab: 0, // Aba inicial
    };
  },
  methods: {
    onTabChange(selectedTabIndex) {
      // console.log("Refs disponíveis:", this.$refs);
      if (selectedTabIndex === 0 && this.$refs.equipamentoTab) {
        // console.log("Inicializando Equipamento...");
        this.$refs.equipamentoTab.initialize();
      } else if (selectedTabIndex === 1 && this.$refs.esterilizavelTab) {
        // console.log("Inicializando Esterilizável...");
        this.$refs.esterilizavelTab.initialize();
      }
    },
  },


};
</script>
