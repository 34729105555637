<template>
  <div class="pa-4" dense>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense >
        <v-col cols="2">
          <v-text-field dense label="ID" v-model="localEquipamento.id" disabled outlined ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field dense label="Nome do Equipamento" v-model="localEquipamento.nome_equipamento.nome" disabled outlined ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select dense outlined label="Status" v-model="localEquipamento.ativo" :items="ativoOptions" item-value="value" item-text="nome" :disabled="!isAtivoEnabled"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field dense label="Disponibilidade Atual" v-model="originalDisponibilidade" outlined disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
         <v-col cols="3" >
          <v-text-field dense  label="Data Inlusão Orçamento" :value="localEquipamento.data_inclusao_orcamento" type="date" outlined disabled></v-text-field>
        </v-col>
         <v-col cols="3" >
          <v-text-field dense  label="Data de Requisição" :value="localEquipamento.data_requisicao" type="date" outlined disabled></v-text-field>
        </v-col>
        <v-col cols="3" v-if="originalDisponibilidadeID !== 3 && localEquipamento.disponibilidade.id !== 2">
          <v-text-field dense  label="Data Prevista da Implantação" :value="localEquipamento.data_prevista_implantacao" type="date" outlined disabled></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense  label="Data da Implantação" :value="localEquipamento.data_implantacao" type="date" outlined   disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row desen v-if="this.localEquipamento.ativo" >
        <v-col cols="3" v-if="this.localEquipamento.disponibilidade.id !== 13  && this.localEquipamento.disponibilidade.id !== 14">
          <v-select dense label="Nova Disponibilidade" v-model="localEquipamento.disponibilidade.id" :items="computedDisponibilidade" item-value="id" item-text="nome" outlined :loading="loadingDisponibilidade"
            :disabled="this.localEquipamento.ativo === false" ></v-select>
        </v-col>
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 2">
          <v-text-field dense  label="Data Inlusão Orçamento" v-model="localEquipamento.data_inclusao_orcamento" type="date" outlined :disabled="!isAtivoEnabled"></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 3 || localEquipamento.disponibilidade.id === 1">
          <v-text-field dense  label="Data de Requisição" v-model="hojeData" type="date" outlined :disabled="!isRequisicaoEnabled"></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 3 || localEquipamento.disponibilidade.id === 1">
          <v-text-field dense  label="Data Prevista de Implantação" v-model="hojeDataPrevista" type="date" outlined  :disabled="!isRequisicaoEnabled" ></v-text-field>
        </v-col>
        <!-- <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 1">
          <v-text-field dense  label="Data de Implantação" v-model="localEquipamento.data_implantacao" type="date" outlined  :disabled="!isRequisicaoEnabled" ></v-text-field>
        </v-col> -->
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 4">
          <v-text-field dense  label="Data da Solicitação de Troca" v-model="hojeData" type="date" outlined :disabled="!isTrocaEnabled"></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 4">
          <v-text-field dense  label="Data da Prevista de Troca" v-model="hojeDataPrevista" type="date" outlined :disabled="!isTrocaEnabled"></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 12">
          <v-text-field dense  label="Data da Solicitação de Retirada" v-model="hojeData" type="date" outlined :disabled="!isRetiradaEnabled"></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEquipamento.disponibilidade.id === 12">
          <v-text-field dense  label="Data Prevista para Retirada" v-model="hojeDataPrevista" type="date" outlined :disabled="!isRetiradaEnabled"></v-text-field>
        </v-col>

      </v-row>
      <v-row dense v-if="this.localEquipamento.disponibilidade.id !== 13  && this.localEquipamento.disponibilidade.id !== 14">
        <v-col >
          <v-text-field dense label="Observação" v-model="localEquipamento.obs" outlined ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense v-if="this.localEquipamento.disponibilidade.id !== 13  && this.localEquipamento.disponibilidade.id !== 14">
        <v-col class="text-end"> 
          <!-- <v-btn class="mr-4 ml-5 elevation-0" @click="cancelarEntrega" :loading="isLoadingaCancelar" color="red" v-if="originalDisponibilidadeID === 3" > Cancelar "{{originalDisponibilidade}}""  </v-btn> -->
          <ConfirmButton color="success" :loading="isSubmitting">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import api from '@/http'
import ConfirmButton from '../../../../../ConfirmButton.vue';
import UtilsFunc from '../../../../../../service/utilsFunc'

const { withCRUDUtils } = UtilsFunc

export default {
  name: "EditarEquipamento",
  data() {
    return withCRUDUtils({
      disponibilidade: [],
      isSubmitting: false,
      loadingDisponibilidade: true,
      isLoadingaCancelar: false,
      localEquipamento: { ...this.equipamento },
      initialDataSolicitacaoRetirada: this.equipamento?.data_solicitacao_retirada || '',
      initialDataRequisicao: this.equipamento?.data_requisicao || '',
      initialDataImplantacao: this.equipamento?.data_prevista_implantacao || '',
      initialOrcamento: this.equipamento?.data_inclusao_orcamento || '',
      dataSolicitacaoRetiradaChanged: false,
      dataRequisicaoChanged: false,
      dataImplantacaoChanged: false,
      dataOrcamentoChanged: false,
      hojeData: '',
      hojeDataPrevista: '',
    });
  },
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    loading: Boolean,
    paciente_id: Number,
    ativoOptions: {
      type: Array,
      default: () => [
        { value: true, label: 'Ativo' },
        { value: false, label: 'Inativo' },
      ],
    },
    equipamento: { type: Object, default: null },
  },
  computed: {
    originalDisponibilidade() {
      return this.equipamento.disponibilidade.nome;
    },
    originalDisponibilidadeID() {
      return this.equipamento.disponibilidade.id;
    },
    isAtivoEnabled() {
      return [2].includes(this.localEquipamento.disponibilidade.id);
    },
    isRequisicaoEnabled() {
      return [3].includes(this.localEquipamento.disponibilidade.id);
    },
    isTrocaEnabled() {
      return [4].includes(this.localEquipamento.disponibilidade.id);
    },
    isRetiradaEnabled() {
      return [12].includes(this.localEquipamento.disponibilidade.id);
    },
    computedDisponibilidade() {
      // Lista base de disponibilidades
      let filtered = this.disponibilidade.filter(d => [2, 3, 4, 12].includes(d.id));

      // Aplica a regra se a disponibilidade original for 2
      if (this.originalDisponibilidadeID === 2 || this.originalDisponibilidadeID === 3) {
        filtered = filtered.filter(d => [2, 3].includes(d.id));
      } else {
        filtered = filtered.filter(d => [1, 4, 12].includes(d.id));
      }
      const currentDisponibilidade = this.disponibilidade.find(d => d.id === this.localEquipamento.disponibilidade.id);
      // const filtered = this.disponibilidade.filter(d => [2,4, 3, 12].includes(d.id));
      if (currentDisponibilidade && !filtered.some(d => d.id === currentDisponibilidade.id)) {
        filtered.push(currentDisponibilidade);
      }
      return filtered;
    },
  },
  methods: {
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      const today = new Date().toISOString().split('T')[0]; // Obtém a data de hoje no formato YYYY-MM-DD

      if (this.localEquipamento.disponibilidade === 3) {
        if (!this.localEquipamento.data_requisicao || !this.localEquipamento.data_prevista_implantacao || this.localEquipamento.data_requisicao == "" || this.localEquipamento.data_prevista_implantacao == "") {
          this.$toast.error("Data de Requisição e Data Prevista da Instalação são obrigatórias para esta disponibilidade.");
          return;
        }

        if (this.dataRequisicaoChanged && (this.localEquipamento.data_requisicao < today || this.localEquipamento.data_requisicao > today)) {
          this.$toast.error("Data de Requisição não pode ser anterior e nem posterior a hoje.");
          return;
        }

        if (this.dataImplantacaoChanged && this.localEquipamento.data_prevista_implantacao < today) {
          this.$toast.error("Data Prevista de Instalação não pode ser anterior a hoje.");
          return;
        }
      }

      if (this.localEquipamento.disponibilidade === 4) {
        if (this.hojeData === null || this.hojeDataPrevista === null) {
          this.$toast.error("Data de Solicitação de troca é obrigatório para esta disponibilidade.");
          return;
        }

        if (this.dataSolicitacaotrocaChanged && (this.hojeData < today || this.hojeDataPrevista > today)) {
          this.$toast.error("Data de Solicitação de troca não pode ser anterior e nem posterior a hoje.");
          return;
        }
      }
      if (this.localEquipamento.disponibilidade === 12) {
        if (this.hojeData === null || this.hojeDataPrevista === null) {
          this.$toast.error("Data de Solicitação de retirada é obrigatório para esta disponibilidade.");
          return;
        }

        if (this.dataSolicitacaoretiradaChanged && (this.hojeData < today || this.hojeDataPrevista > today)) {
          this.$toast.error("Data de Solicitação de retirada não pode ser anterior e nem posterior a hoje.");
          return;
        }
      }

      if (this.localEquipamento.disponibilidade === 2) {
        if (!this.localEquipamento.data_inclusao_orcamento || this.localEquipamento.data_inclusao_orcamento == "") {
          this.$toast.error("Data da Inclusão no Orçamento é obrigatório para esta disponibilidade.");
          return;
        }

        if (this.dataOrcamentoChanged && (this.localEquipamento.data_inclusao_orcamento < today || this.localEquipamento.data_inclusao_orcamento > today)) {
          this.$toast.error("Data da Inclusão no Orçamento não pode ser anterior e nem posterior a hoje.");
          return;
        }
      }

      this.isSubmitting = true; // Ativar o loading
      const inativado = this.localEquipamento.ativo === false ? 2 : this.localEquipamento.disponibilidade.id
      
      const fields = { // paciente/equipamento
        id: this.localEquipamento.id,
        disponibilidade: inativado,
        ativo: Boolean(this.localEquipamento.ativo),
        data_inclusao_orcamento: this.localEquipamento.data_inclusao_orcamento ? this.localEquipamento.data_inclusao_orcamento : null,
        obs: this.localEquipamento.obs,
      };

      if (this.localEquipamento.disponibilidade?.id === 3) { // Requisitar
          fields.data_requisicao = this.hojeData || null;
          fields.data_prevista_implantacao = this.hojeDataPrevista || null;
          fields.requisicao = true;
          
      }
      if (this.localEquipamento.disponibilidade?.id === 4) { // Requisitar Troca e altera em equipamntos/equipamento
          fields.data_solicitacao_troca = this.hojeData || null;
          fields.data_prevista_solicitacao_troca = this.hojeDataPrevista || null;
          fields.troca = true;
          fields.data_solicitacao_retirada =  null;
          fields.data_prevista_solicitacao_retirada =  null;
          fields.retirar = null;
          try {
            await api.put(`/equipamentos/equipamento_editar/${this.localEquipamento.equipamento.id}/`, {
                id: this.localEquipamento.equipamento.id,
                disponibilidade: 4,
              })
          } catch (error) {
            console.error('Erro ao atualizar equipamento:', error);
          }
      }
      if (this.localEquipamento.disponibilidade?.id === 12) { // Requisitar Retirada e altera em equipamntos/equipamento
          fields.data_solicitacao_retirada = this.hojeData || null;
          fields.data_prevista_solicitacao_retirada = this.hojeDataPrevista || null;
          fields.retirar = true;
          fields.data_solicitacao_troca =  null;
          fields.data_prevista_solicitacao_troca = null;
          fields.troca = null;
          try {
            await api.put(`/equipamentos/equipamento_editar/${this.localEquipamento.equipamento.id}/`, {
                id: this.localEquipamento.equipamento.id,
                disponibilidade: 12,
              })
           
          } catch (error) {
            console.error('Erro ao atualizar equipamento:', error);
            this.$toast.error('Erro ao atualizar equipamento.');
          }
      }

      // console.log(fields);
      await this.onSubmit(fields).finally(() => {
        this.isSubmitting = false; // Desativar o loading
      });
    },
    async getDisponibilidade() {
      this.loadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingDisponibilidade = false;
      }
    },
    async cancelarEntrega() {
      this.isLoadingaCancelar = true;

      try {
        const fields = { // paciente/equipamento
          id: this.localEquipamento.id,
          // disponibilidade: 1,
        }
        if (this.localEquipamento.disponibilidade?.id === 3) { // Requisitar Requisitar
          fields.data_requisicao = null;
          fields.data_prevista_implantacao =  null;
          fields.disponibilidade = 2;
          fields.requisicao = null;
 
        }
        if (this.localEquipamento.disponibilidade?.id === 2) { // para Orçamento
          fields.ativo = false
          fields.data_requisicao = null;
          fields.data_prevista_implantacao =  null;
          fields.disponibilidade = 2;
          fields.requisicao = null;
          // try {
          //   await api.put(`atendimentos/equipamento/${this.localEquipamento.idEquipamentoOrcamento}/`, {
          //     id: this.localEquipamento.idEquipamentoOrcamento,
          //     ativo: false,
          //   });
          //   console.log("Equipamento no orçamento atualizado com sucesso");
          // } catch (error) {
          //   console.error("Erro ao atualizar equipamento no orçamento:", error);
          //   this.$toast.error("Erro ao atualizar equipamento no orçamento.");
          //   return;
          // }
        }
        if (this.localEquipamento.disponibilidade?.id === 12) { // Requisitar Retirada
          fields.data_solicitacao_retirada = null;
          fields.data_prevista_solicitacao_retirada = null;
          fields.data_solicitacao_troca = null;
          fields.data_prevista_solicitacao_troca = null;
          fields.retirada = null;
          fields.troca = null;
          fields.disponibilidade = 1;
          try {
            await api.put(`/equipamentos/equipamento_editar/${this.localEquipamento.equipamento.id}/`, {
                id: this.localEquipamento.equipamento.id,
                disponibilidade: 1,
              })
          } catch (error) {
            console.error('Erro ao atualizar equipamento em paciente:', error);
          }
          // try {
          //   await api.put(`atendimentos/equipamento/${this.localEquipamento.idEquipamentoOrcamento}/`, {
          //     id: this.localEquipamento.idEquipamentoOrcamento,
          //     data_fim_equipamento: this.hojeDataPrevista,
          //     ativo: false,
          //   });
          //   console.log("Equipamento no orçamento atualizado com sucesso");
          // } catch (error) {
          //   console.error("Erro ao atualizar equipamento no orçamento:", error);
          //   this.$toast.error("Erro ao atualizar equipamento no orçamento.");
          //   return;
          // }
        }
        if (this.localEquipamento.disponibilidade?.id === 4) { // Requisitar Troca
          fields.data_solicitacao_retirada = null;
          fields.data_prevista_solicitacao_retirada = null;
          fields.data_solicitacao_troca = null;
          fields.data_prevista_solicitacao_troca = null;
          fields.troca = null;
          fields.retirada = null;
          fields.disponibilidade = 1

          // try {
          //   await api.put(`/equipamentos/equipamento_editar/${this.localEquipamento.equipamento.id}/`, {
          //       id: this.localEquipamento.id,
          //       disponibilidade: 1,
          //     })
           
          // } catch (error) {
          //   console.error('Erro ao atualizar equipamento em paciente:', error);
          // }
        }
      // console.log(fields)
        await this.onSubmit(fields).finally(() => {
        this.isSubmitting = false; // Desativar o loading
      });   

        this.$toast.success('Cancelado com sucesso!');
      } catch (error) {
        console.error('Erro ao cancelar:', error);
        this.$toast.error('Erro ao cancelar a requisição.');
      } finally {
        this.isLoadingaCancelar = false;

        // Emite o evento para o componente pai
        this.$emit('close');
      }
    },
    applyDateFieldRules(disponibilidadeId) {
      const today = new Date().toISOString().substr(0, 10);
      if (disponibilidadeId === 4) {
        this.localEquipamento.data_solicitacao_retirada = this.initialDataSolicitacaoRetirada || today;
      } else {
          this.localEquipamento.data_solicitacao_retirada = this.initialDataSolicitacaoRetirada;
        }
      if (disponibilidadeId === 2) {
        this.localEquipamento.data_inclusao_orcamento = this.initialOrcamento || today;
      } else {
          this.localEquipamento.data_inclusao_orcamento = this.initialOrcamento;
        }
      if (disponibilidadeId === 3) {
        this.localEquipamento.data_requisicao = this.initialDataRequisicao || today;
        this.localEquipamento.data_prevista_implantacao = this.initialDataImplantacao || today;
      } else {
          this.localEquipamento.data_requisicao = this.initialDataRequisicao;
          this.localEquipamento.data_prevista_implantacao = this.initialDataImplantacao;
        }
    },
  },
  watch: {
    equipamento: {
      immediate: true,
      handler(newVal) {
        this.localEquipamento = { ...newVal };
        this.initialDataSolicitacaoRetirada = newVal.data_solicitacao_retirada || '';
        this.initialDataRequisicao = newVal.data_requisicao || '';
        this.initialDataImplantacao = newVal.data_prevista_implantacao || '';
        this.initialOrcamento = newVal.data_inclusao_orcamento || '';
        
      },
    },
    'localEquipamento.disponibilidade.id': {
        immediate: true,
        handler(newVal) {
          this.applyDateFieldRules(newVal);
        }
      },
      'localEquipamento.data_solicitacao_retirada': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataSolicitacaoRetiradaChanged = true;
      }
    },
    'localEquipamento.data_requisicao': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataRequisicaoChanged = true;
      }
    },
    'localEquipamento.data_prevista_implantacao': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataImplantacaoChanged = true;
      }
    },
    'localEquipamento.data_inclusao_orcamento': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataOrcamentoChanged = true;
      }
    },
  },  
  mounted() {
    this.getDisponibilidade();
    const hoje = new Date();
    const amanha = new Date();
    amanha.setDate(hoje.getDate() + 1); // Garante que o cálculo de dias seja correto

    const formatarData = (data) => {
      return data.getFullYear() + '-' + 
        String(data.getMonth() + 1).padStart(2, '0') + '-' + 
        String(data.getDate()).padStart(2, '0');
    };

    this.hojeData = formatarData(hoje);
    this.hojeDataPrevista = formatarData(amanha);

  }
}
</script>