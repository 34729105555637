import { render, staticRenderFns } from "./Novo.vue?vue&type=template&id=6703dc48&scoped=true"
import script from "./Novo.vue?vue&type=script&lang=js"
export * from "./Novo.vue?vue&type=script&lang=js"
import style0 from "./Novo.vue?vue&type=style&index=0&id=6703dc48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6703dc48",
  null
  
)

export default component.exports