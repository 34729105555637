<template>
  <div>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense>
        <v-col cols="2" >
          <v-select dense label="Disponibilidade" :items="disponibilidadeOptions" item-text="nome" item-value="id" v-model="disponibilidadeescolhida" outlined  :rules="requiredField" />
        </v-col>
        <v-col cols="2">
          <v-autocomplete  dense  label="Classe Terapêutica" v-model="classe_terapeutica_selecionada" :items="classe_terapeutica"  item-text="nome"  item-value="id"  outlined  :loading="loadingGeral" clearable/>
        </v-col>
        <v-col>
          <v-autocomplete  dense  label="Medicamento" v-model="medicamento"  :items="medicamentosFiltrados"  item-text="nome"  item-value="id"  outlined  :rules="requiredField"  :loading="loadingGeral" @change="atualizarMedicamentoSelecionado"/>
        </v-col>
        <v-col v-if="medicamento" cols="2">
          <v-tooltip top >
            <template v-slot:activator="{ on, attrs }">
              <v-alert 
                v-bind="attrs" 
                v-on="on" 
                :type="medicamento.padrao ? 'success' : 'warning'" 
                dense 
                class="text-body-2 text-center"
                style="font-size: 8px; padding: 7px;"
              >
                {{ medicamento.padrao ? 'PADRÃO' : 'NÃO PADRÃO' }}
              </v-alert>
            </template>
            <span>{{ medicamento.padrao ? 'Este medicamento é padronizado na empresa..' : 'Este medicamento não é padronizado na empresa. Provavelmente não temos em estoque.' }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
     
      <v-row dense v-if="exibirObsViaSonda">
        <v-col cols="12">
          <v-alert type="info" dense>
          Administrado Via Sonda - OBS: {{ medicamento.obs_via_sonda }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense v-if="altorisco">
        <v-col cols="12">
          <v-alert type="error" dense>
          Medicamento Alto Risco - OBS: {{ medicamento.qual_risco }}
          </v-alert>
        </v-col>
      </v-row>


      <v-row dense>
        <v-col cols="2" v-if="medicamento">
          <v-text-field dense label="Como é prescrito" v-model="prescricaoText" outlined readonly ></v-text-field>
        </v-col>
        <v-col cols="1" >
          <v-text-field dense  label="Qtd." type="number" outlined v-model="quantidade_medicameneto" :rules="requiredField" ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select  dense  :items="statusOptions"  label="SOS"  v-model="sos"  item-text="label"  item-value="value"  outlined  :rules="requiredField" />
        </v-col>
        <v-col v-if="sos === false">
          <v-autocomplete  dense  label="Medicamento Associado para administrar junto com a medicamento principal" v-model="medicamento_complementar" :items="medicamentoCompelementar"  item-text="nome" item-value="id"  outlined   :loading="loadingGeral" clearable/>
        </v-col>
        <v-col cols="1" v-if="medicamento_complementar">
          <v-text-field dense  label="Qtd." type="number" outlined v-model="quantidade_medicameneto_complementar" ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete dense label="Responsável Fornecimento" v-model="quemforneceSelecionado" :items="quemfornece" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col >
          <v-text-field dense label="Data Inicio" v-model="dataInicioSelecioanda" required outlined clearable type="date" :rules="requiredField" />
        </v-col>
        <v-col v-if="sos === false">
          <v-text-field dense label="Hora Início" v-model="horaInicioSelecioanda" required outlined clearable type="time" :rules="requiredField" />
        </v-col>
        <!-- <v-col >
          <v-text-field dense label="Hora Fim" v-model="horaFimSelecioanda" required outlined clearable type="time"  />
        </v-col> -->
        <!-- <v-col >
          <v-text-field dense label="Data Fim" v-model="dataFimSelecioanda" required outlined clearable type="date"/>
        </v-col> -->
        <v-col v-if="sos === false">
          <v-text-field dense label="Dias de Tratamento" v-model="diasTratamentoSelecionado" required outlined clearable type="number"  />
        </v-col>
        <v-col v-if="sos === false">
          <v-text-field dense label="Quantas Administrações" v-model="quantidadeTratamentoSelecionado" required outlined clearable type="number"  />
        </v-col>
        <v-col v-if="sos === false">
          <v-autocomplete dense label="Frequencia" v-model="frequenciaSelecionada" :items="frequenciasFiltradas" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
      </v-row>
      <v-row dense >
        
      </v-row>

      <v-row dense >
        <v-col v-if="sos === false">
          <v-autocomplete dense label="Dia da Semana" item-text="nome" v-model="diasSemanaSelecioando" :items="diasSemana" item-value="id" outlined :loading="loadingGeral" multiple chips >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="item" :key="index" close @click:close="removeViaAdministracao(item.id)">
                {{ item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete  dense  label="Via de Administração" v-model="viaadministracaoSelecioando"  :items="viaAdministracaoFiltrada"  :item-text="viaAdministracaoText"  item-value="id"  outlined   :loading="loadingGeral" :rules="requiredField" />
        </v-col>
      </v-row>
      <v-row dense v-if="sos === false">
        <v-col cols="12" class="mb-2">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ horariosSelecionadosTitulo }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col v-for="(item, index) in horariosDisponiveis" :key="index" cols="1">
                      <v-checkbox
                        v-model="horariosSelecionados"
                        :label="item.hora"
                        :value="item.hora"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row dense class="mt-5">
      <v-col  v-if="disponibilidadeescolhida === 2" cols="3">
        <v-text-field dense label="Data de Inclusão no Orçaemnto" v-model="hojeData" required outlined clearable type="date" :rules="requiredField" />
      </v-col>
      <v-col  v-if="disponibilidadeescolhida === 3" cols="3">
        <v-text-field dense label="Data Requisição" v-model="hojeData" required outlined clearable type="date" :rules="requiredField" />
      </v-col>
      <v-col  v-if="disponibilidadeescolhida === 3" cols="3">
        <v-text-field dense label="Data para Entrega" v-model="hojeDataPrevista" required outlined clearable type="date" :rules="requiredField" />
      </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-text-field dense label="Observações / Orientações para Prescrição / Administração" v-model="obsSelecioanda" outlined />
      </v-row>
      <v-row class="justify-end" dense>
        <v-col class="text-end">
          <v-btn color="red" text class="mr-2" elevation="0" @click="limpar"> limpar Campos </v-btn>
          <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template> 

<script>
import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';
import api from "../../../../../../http";

export default {
  name: "NovoMedicamento",
  props: {
    operadoraId: Number,
    onSubmit: Function,
    paciente: Number,
  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      isLoading: true,
      requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
      loadingGeral: false,
      isFormValid: false,
      isSubmitting: false,
      loading: false,
      medicamentos: [], 
      medicamentoCompelementar: [],
      quemfornece: [],
      quemforneceSelecionado: "", 
      diasSemanaSelecioando: "", 
      disponibilidadeOptions: [],
      diasSemana: [],
      frequencia: [],
      viaAdministracao: [],
      classe_terapeutica: [],
      disponibilidadeescolhida: null,
      medicamento: null,
      medicamento_complementar: null,
      frequenciaSelecionada: null,
      sos: null,
      dataInicioSelecioanda: null,
      // dataFimSelecioanda: null,
      horaInicioSelecioanda: null,
      // horaFimSelecioanda: null,
      diasTratamentoSelecionado: null,
      quantidade_medicameneto: 1,
      hojeData: null,
      hojeDataPrevista: null,
      viaadministracaoSelecioando: null,
      viaAdministracaoFiltrada: [],
      obsSelecioanda: null,
      quantidadeTratamentoSelecionado: null, 
      horariosDisponiveis: [], 
      horariosSelecionados: [], 
      quantidade_medicameneto_complementar: 1,
      medicamentosFiltrados: [], // Apenas os medicamentos filtrados pela Classe Terapêutica
      classe_terapeutica_selecionada: null, 
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
    }),
  methods: {
    async getMedicamento() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get("/matmed/matmeds/");
        this.medicamentos = data.filter(item => item.ativo && item.tipo.id === 1);
        // Garantir que todos os medicamentos sejam listados inicialmente
        this.medicamentosFiltrados = [...this.medicamentos];

        // Aplica o filtro caso já haja uma classe terapêutica selecionada
        this.filtrarMedicamentosPorClasseTerapeutica();
        // console.log('medicamento', this.medicamentos)
        this.medicamentoCompelementar = data.filter(item => [53, 54].includes(item.id)) // relacionar os medicamdnro complementares
      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getQuemFornece() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get("/pacientes/quem_fornece/");
        this.quemfornece = data.filter(item => item.ativo);
        // console.log("📌 Quem Fornece carregado:", this.quemfornece);
        // ✅ Definir automaticamente o ID 1 caso ele exista na lista
        const fornecimentoPadrao = this.quemfornece.find(item => item.id === 1);
        if (fornecimentoPadrao) {
          this.quemforneceSelecionado = fornecimentoPadrao.id;
        }
      } catch (error) {
        console.error("❌ Erro ao carregar quem fornece:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDisponibilidade() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidadeOptions = data.filter(item => [3, 2].includes(item.id));
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDiasSemana() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/dias_semana/`);
        this.diasSemana = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getFrequencia() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/atendimentos/frequencias/`);
        this.frequencia = data
          .filter(item => item.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(this.frequencia)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getViaAdministracao() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/matmed/via_administracao/`);
        this.viaAdministracao = data
          .filter(item => item.ativo)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getClasseTerapeutica() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/matmed/classe_terapeutica/`);
        this.classe_terapeutica = data.filter(item => item.ativo)
        // console.log(this.classe_terapeutica)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      const fields =  {
        paciente: this.paciente,
        operadora: this.operadoraId,
        disponibilidade: this.disponibilidadeescolhida,
        medicamento: this.medicamento.id,
        quantidade_medicameneto: this.quantidade_medicameneto,
        sos: this.sos,
        quem_fornece: this.quemforneceSelecionado,
        data_inicio: this.dataInicioSelecioanda,
        via_administracao: this.viaadministracaoSelecioando,
        obs: this.obsSelecioanda,
      }
      if (this.sos === false) {
        fields.medicamento_complementar = this.medicamento_complementar
        fields.dias_semana = this.diasSemanaSelecioando
        fields.hora_inicio = this.horaInicioSelecioanda
        fields.horarios_administracao = this.horariosSelecionados.join(";")
        fields.dias_tratamento = this.diasTratamentoSelecionado
        fields.quantidade_tratamento = this.quantidadeTratamentoSelecionado
        fields.frequencia = this.frequenciaSelecionada
      }
      if(this.disponibilidadeescolhida === 3){
        fields.data_requisicao = this.hojeData
        fields.data_prevista_implantacao = this.hojeDataPrevista
        fields.requisicao = true
      }
      if(this.disponibilidadeescolhida === 2){
        fields.data_orcamento = this.hojeData
      }
      console.log('Enviado->', fields)

      if (this.medicamento_complementar !== null) {
        const medicamentoComplementar = { 
          paciente: this.paciente,
          operadora: this.operadoraId,
          disponibilidade: this.disponibilidadeescolhida,
          medicamento: this.medicamento_complementar,
          quantidade_medicameneto: 1,
          sos: this.sos,
          quem_fornece: this.quemforneceSelecionado,
          data_inicio: this.dataInicioSelecioanda,
          via_administracao: this.viaadministracaoSelecioando,


        }
        if(this.disponibilidadeescolhida === 3){
          medicamentoComplementar.data_requisicao = this.hojeData
          medicamentoComplementar.data_prevista_implantacao = this.hojeDataPrevista
        }
          
        console.log('medicamentoComplementar -> ', medicamentoComplementar)

      }
      // const fieldsArray = [fields];
      //  await this.onSubmit(fieldsArray).finally(() => {

      //   this.isSubmitting = false;
      // });


    },
    gerarHorarios() {
      let horarios = [];
      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 30) {
          let horaFormatada = `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}`;
          horarios.push({ hora: horaFormatada });
        }
      }
      this.horariosDisponiveis = horarios;
    },
    removeHorario(hora) {
      this.horariosSelecionados = this.horariosSelecionados.filter(h => h !== hora);
    },
    atualizarMedicamentoSelecionado() {
      if (!this.medicamento) return;
      
      const medicamentoCompleto = this.medicamentos.find(med => med.id === this.medicamento);
      
      if (medicamentoCompleto) {
        this.medicamento = medicamentoCompleto; // Armazena o objeto completo
        this.atualizarViasAdministracao();
      }
    },
     atualizarViasAdministracao() {
      if (!this.medicamento || !this.medicamento.via_administracao) {
        this.viaAdministracaoFiltrada = []; // Se não houver medicamento ou vias, limpa a lista
      } else {
        this.viaAdministracaoFiltrada = this.medicamento.via_administracao.map(via => ({
          id: via.id,
          nome: via.nome,
          sigla: via.sigla
        }));
      }
    },
    limpar(){
      this.disponibilidadeescolhida =null,
      this.medicamento =null,
      this.medicamento_complementar =null,
      this.frequenciaSelecionada =null,
      this.sos =null,
      this.dataInicioSelecioanda =null,
      // this.dataFimSelecioanda =null,
      this.horaInicioSelecioanda =null,
      // this.horaFimSelecioanda =null,
      this.diasTratamentoSelecionado =null,
      this.diasSemanaSelecioando = [],
      this.hojeData =null,
      this.hojeDataPrevista =null,
      this.viaadministracaoSelecioando =null,
      this.viaAdministracaoFiltrada = null,
      this.quantidadeTratamentoSelecionado = null,
      this.obsSelecioanda =null,
      this.horariosDisponiveis =[], 
      this.horariosSelecionados =[],
      this.classe_terapeutica = [],
      this.quantidade_medicameneto_complementar = 1,
      this.quantidade_medicameneto = 1,
      this.inicializar()
      
    },
    inicializar(){
      this.getMedicamento();
      this.filtrarMedicamentosPorClasseTerapeutica();
      this.gerarHorarios();
      this.getQuemFornece();
      this.getDisponibilidade()
      this.getDiasSemana()
      this.getFrequencia()
      this.getViaAdministracao()
      this.getClasseTerapeutica()
      this.sos = false
      const hoje = new Date();
      const amanha = new Date();
      amanha.setDate(hoje.getDate() + 1);
      const formatarData = (data) => {
        return data.getFullYear() + '-' + 
          String(data.getMonth() + 1).padStart(2, '0') + '-' + 
          String(data.getDate()).padStart(2, '0');
      };
      this.hojeData = formatarData(hoje);
      this.dataInicioSelecioanda = formatarData(hoje);
      this.hojeDataPrevista = formatarData(amanha);
      this.quantidade_medicameneto_complementar = 1;
    },
    filtrarMedicamentosPorClasseTerapeutica() {
      if (!this.classe_terapeutica_selecionada) {
        // Exibir todos os medicamentos se nenhuma classe terapêutica for selecionada
        this.medicamentosFiltrados = [...this.medicamentos];
      } else {
        // Filtrar medicamentos pela classe terapêutica selecionada
        this.medicamentosFiltrados = this.medicamentos.filter(med =>
          med.classe_terapeutica && med.classe_terapeutica.id === this.classe_terapeutica_selecionada
        );
      }
    },
    marcarHorariosAutomaticamente() {
    if (!this.horaInicioSelecioanda) return; // Se não houver horário inicial, não faz nada
    
    // Converter a hora para número para facilitar cálculos
    const [hora, minuto] = this.horaInicioSelecioanda.split(':').map(Number);
    let horarios = [`${String(hora).padStart(2, "0")}:${String(minuto).padStart(2, "0")}`];

    // Verifica qual frequência foi selecionada
    const frequenciaSelecionada = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
    if (!frequenciaSelecionada) return;

    // Apenas as frequências permitidas (3, 4, 15, 17 e 18) terão múltiplos horários
    const frequenciasPermitidas = [3, 4, 15, 16, 17, 18];

    if (frequenciasPermitidas.includes(frequenciaSelecionada.id)) {
      const qt = frequenciaSelecionada.qt; // Quantidade de administrações por dia
      let intervalo = Math.floor(24 / qt); // Define o intervalo de tempo entre administrações

      for (let i = 1; i < qt; i++) {
        let novaHora = (hora + (i * intervalo)) % 24; // Calcula a nova hora
        let novaHoraFormatada = `${String(novaHora).padStart(2, "0")}:${String(minuto).padStart(2, "0")}`;
        horarios.push(novaHoraFormatada);
      }
    }

    // Ordena os horários de forma crescente
    horarios.sort((a, b) => {
      const [horaA, minutoA] = a.split(":").map(Number);
      const [horaB, minutoB] = b.split(":").map(Number);
      return horaA - horaB || minutoA - minutoB;
    });

    // Atualiza os horários selecionados no campo "Selecionar Horários"
    this.horariosSelecionados = horarios;
    },
    marcarDiaDaSemanaAutomaticamente() {
      if (!this.frequenciaSelecionada) return;

      const frequenciasDiarias = [3, 4, 15, 16, 17, 18];
      const frequenciasQueMarcamUmDia = [12, 11, 10, 1, 14, 13];
      
      if (this.diasTratamentoSelecionado) {
          this.marcarDiasTratamento();
          return;
      }

      if (frequenciasDiarias.includes(this.frequenciaSelecionada)) {
          this.diasSemanaSelecioando = this.diasSemana.map(dia => dia.id);
          return;
      }

      if (frequenciasQueMarcamUmDia.includes(this.frequenciaSelecionada)) {
          const hoje = new Date();
          let diaSemanaAtual = hoje.getDay();
          if (diaSemanaAtual === 0 || diaSemanaAtual === 6) {
              diaSemanaAtual = 1;
          }
          this.diasSemanaSelecioando = [diaSemanaAtual];
          return;
      }
    },
    marcarDiasTratamento() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7);
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      let diasProcessados = 0;
      let proximoDia = diaAtual;
      
      // Continua adicionando dias até atingir a quantidade desejada
      while (diasProcessados < quantidade) {
          // Adiciona o dia atual se ainda não foi adicionado
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasProcessados++;
          }
          
          // Move para o próximo dia
          proximoDia++;
          // Se passou de domingo (7), volta para segunda (1)
          if (proximoDia > 7) {
              proximoDia = 1;
          }
      }
      
      // Ordena os dias para garantir a sequência correta
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;

      // console.log("Dias selecionados:", diasSelecionados); // Para debug
    },
    marcarDiasTratamentoComFinaisDeSemana() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7); // Limita a 7 dias, já que não podemos ter mais que isso
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      
      // Primeiro, adicionamos o dia atual
      diasSelecionados.push(diaAtual);
      
      // Então, adicionamos os próximos dias necessários
      let diasRestantes = quantidade - 1;
      let proximoDia = diaAtual;
      
      while (diasRestantes > 0) {
          proximoDia++;
          if (proximoDia > 7) {
              proximoDia = 1; // Volta para segunda quando passar de domingo
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasRestantes--;
          }
      }
      
      // Ordena os dias
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
    },  
    marcarDiasPorFrequencia(quantidadeDias) {
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasDisponiveis = [1, 2, 3, 4, 5]; // Apenas dias úteis (seg a sex)
      let diasSelecionados = [];
      
      // Remove o dia atual da lista de dias disponíveis se for dia útil
      if (diaAtual <= 5) {
          diasDisponiveis = diasDisponiveis.filter(d => d !== diaAtual);
      }
      
      // Adiciona o dia atual se for dia útil
      if (diaAtual <= 5) {
          diasSelecionados.push(diaAtual);
      }
      
      // Seleciona os dias restantes necessários
      while (diasSelecionados.length < quantidadeDias && diasDisponiveis.length > 0) {
          // Calcula o intervalo ideal entre os dias
          const intervalo = Math.floor(5 / quantidadeDias);
          
          // Encontra o próximo dia adequado
          let proximoDia = diaAtual + intervalo;
          while (proximoDia > 5) proximoDia -= 5;
          
          // Se o dia já estiver selecionado, tenta o próximo
          while (diasSelecionados.includes(proximoDia) && diasDisponiveis.length > 0) {
              proximoDia = proximoDia + 1 > 5 ? 1 : proximoDia + 1;
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasDisponiveis = diasDisponiveis.filter(d => d !== proximoDia);
          }
          
          diaAtual = proximoDia;
      }
      
      // Ordena os dias selecionados
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
      
      // console.log("Dias selecionados para frequência:", diasSelecionados);
    },        
    removeViaAdministracao(id) {
      this.diasSemanaSelecioando = this.diasSemanaSelecioando.filter(via => via !== id);
    },
  },
  components: { ConfirmButton },
  computed: {
    viaAdministracaoText() {
      return (item) => {
          return `${item.nome} - ${item.sigla} `;
      }
    },
    prescricaoText() {
      return this.medicamento && this.medicamento.apresentacao_prescricao
        ? `${this.medicamento.apresentacao_prescricao.nome} - ${this.medicamento.apresentacao_prescricao.abreviatura}`
        : "Sem prescrição definida";
    },
    horariosSelecionadosTitulo() {
      return this.horariosSelecionados.length > 0
        ? `Horários Selecionados que irão para Prescrição: ${this.horariosSelecionados.join("; ")}`
        : "Selecionar Horários que irão para Prescrição";
    },
    exibirObsViaSonda() {
      return (
        this.medicamento &&
        this.medicamento.via_sonda &&
        this.medicamento.via_sonda.nome?.toLowerCase() === "sim" &&
        this.medicamento.obs_via_sonda
      );
    },
    altorisco() {
      return (
        this.medicamento &&
        this.medicamento.alto_risco === true
      );
    },
   padrao() {
      return this.medicamento && this.medicamento.padrao === true;
    },
    // ✅ Computed para filtrar as frequências dependendo se Dias de Tratamento está preenchido ou não
    frequenciasFiltradas() {
        const frequenciasPermitidas = [3, 4, 15, 16, 17]; // ✅ IDs permitidos se dias de tratamento estiverem preenchidos

        if (this.diasTratamentoSelecionado || this.quantidadeTratamentoSelecionado) {
            // Retorna apenas as frequências permitidas
            return this.frequencia.filter(f => frequenciasPermitidas.includes(f.id));
        }
        return this.frequencia; // Se não houver "Dias de Tratamento", mostra todas as frequências
    },
    
  },
  watch: {
   // Sempre que a classe terapêutica for alterada, refaz o filtro
    classe_terapeutica_selecionada() {
      this.filtrarMedicamentosPorClasseTerapeutica();
      this.medicamento = null;
    },
    // Atualiza os horários quando a "Hora Início" mudar
    horaInicioSelecioanda() {
      this.marcarHorariosAutomaticamente();
    },
    // Atualiza tanto os horários quanto o dia da semana quando a frequência for alterada
    // frequenciaSelecionada(newValue) {
    //   if (!newValue) return;
      
    //   // Limpa os dias selecionados antes de aplicar nova seleção
    //   this.diasSemanaSelecioando = [];
      
    //   // Mapeamento de frequências semanais
    //   const frequenciasSemanais = {
    //       2: 2,  // 2X por semana -> 2 dias
    //       5: 3,  // 3X por semana -> 3 dias
    //       8: 4,  // 4X por semana -> 4 dias
    //       9: 5   // 5X por semana -> 5 dias
    //   };
      
    //   if (frequenciasSemanais[newValue]) {
    //       this.marcarDiasPorFrequencia(frequenciasSemanais[newValue]);
    //   } else {
    //       // Para outras frequências, mantém o comportamento original
    //       this.marcarHorariosAutomaticamente();
    //       this.marcarDiaDaSemanaAutomaticamente();
    //   }
    // },
    diasTratamentoSelecionado(newValue) {
      if (newValue) {
          this.marcarDiasTratamento();
          this.quantidadeTratamentoSelecionado = null; // Zera quantidade de administrações
          
          const frequenciasPermitidas = [3, 4, 15, 16, 17];
          if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
              this.frequenciaSelecionada = null;
          }
      } else {
          this.frequenciaSelecionada = null;
          this.diasSemanaSelecioando = []; // Limpa os dias selecionados quando não houver dias de tratamento
      }
    },
    // quantidadeTratamentoSelecionado(newValue) {
    //   if (newValue) {
    //       this.frequenciaSelecionada = null; // Reseta a frequência
    //       this.diasSemanaSelecioando = []; // Reseta os dias da semana
    //       this.diasTratamentoSelecionado = null; // Zera dias de tratamento

    //       const frequenciasPermitidas = [3, 4, 15, 16, 17];
    //       if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
    //           this.frequenciaSelecionada = null;
    //       }
    //   } else {
    //       this.frequenciaSelecionada = null;
    //       this.diasSemanaSelecioando = []; // Limpa os dias selecionados quando não houver quantidade de administrações
    //   }
    // }
    quantidadeTratamentoSelecionado(newValue) {
    if (newValue) {
      this.diasTratamentoSelecionado = null; // Zera dias de tratamento
      
      // Reset previous selections
      this.diasSemanaSelecioando = [];
      
      // Only proceed if we have both quantity and frequency
      if (this.frequenciaSelecionada) {
        const frequencia = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
        if (frequencia) {
          // Calculate how many days we need based on quantity and frequency
          const administracoesPorDia = frequencia.qt || 1; // Get daily administrations from frequency
          const diasNecessarios = Math.ceil(newValue / administracoesPorDia);
          
          // Limit to 7 days maximum
          const diasAMarcar = Math.min(diasNecessarios, 7);
          
          // Get current day
          let hoje = new Date();
          let diaAtual = hoje.getDay();
          diaAtual = diaAtual === 0 ? 7 : diaAtual; // Convert Sunday from 0 to 7
          
          let diasSelecionados = [];
          let proximoDia = diaAtual;
          
          // Select required number of days
          for (let i = 0; i < diasAMarcar; i++) {
            if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
            }
            
            // Move to next day
            proximoDia++;
            if (proximoDia > 7) proximoDia = 1;
          }
          
          // Sort days for consistent display
          diasSelecionados.sort((a, b) => a - b);
          
          // Update selected days
          this.diasSemanaSelecioando = diasSelecionados;
          
          // Update hours based on frequency
          this.marcarHorariosAutomaticamente();
        }
      }
      
      // Update available frequencies based on quantity
      const frequenciasPermitidas = [3, 4, 15, 16, 17];
      if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
        this.frequenciaSelecionada = null;
      }
    } else {
      // Reset everything if quantity is cleared
      this.frequenciaSelecionada = null;
      this.diasSemanaSelecioando = [];
      this.horariosSelecionados = [];
    }
  },
  
    // Add a new watcher for frequency when quantity is set
    frequenciaSelecionada(newValue) {
      if (this.quantidadeTratamentoSelecionado && newValue) {
        // Recalculate days when frequency changes with quantity set
        const frequencia = this.frequencia.find(f => f.id === newValue);
        if (frequencia) {
          const administracoesPorDia = frequencia.qt || 1;
          const diasNecessarios = Math.ceil(this.quantidadeTratamentoSelecionado / administracoesPorDia);
          const diasAMarcar = Math.min(diasNecessarios, 7);
          
          let hoje = new Date();
          let diaAtual = hoje.getDay();
          diaAtual = diaAtual === 0 ? 7 : diaAtual;
          
          let diasSelecionados = [];
          let proximoDia = diaAtual;
          
          for (let i = 0; i < diasAMarcar; i++) {
            if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
            }
            proximoDia++;
            if (proximoDia > 7) proximoDia = 1;
          }
          
          diasSelecionados.sort((a, b) => a - b);
          this.diasSemanaSelecioando = diasSelecionados;
          
          // Update hours for the new frequency
          this.marcarHorariosAutomaticamente();
        }
      } else {
        // Original frequency behavior when quantity is not set
        if (!newValue) return;
        
        this.diasSemanaSelecioando = [];
        
        const frequenciasSemanais = {
          2: 2,
          5: 3,
          8: 4,
          9: 5
        };
        
        if (frequenciasSemanais[newValue]) {
          this.marcarDiasPorFrequencia(frequenciasSemanais[newValue]);
        } else {
          this.marcarHorariosAutomaticamente();
          this.marcarDiaDaSemanaAutomaticamente();
        }
      }
    }
  },
  mounted() {
    this.inicializar()
  },
};
</script>
