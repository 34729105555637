<template>
  <v-container fluid class="mr-2 ml-2" @keydown.enter.prevent="pesquisar">
    <h1 class="h1">Prontuário</h1>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc" 
          :loading="isLoading('get:patients')" loading-text="Carregando Informações...." :items="filteredRows" :search="search" :headers="headersWithQuantity"
          :single-expand="singleExpand" :expanded.sync="expanded" show-expand class="fixed-header-table" fixed-header  height="700px" :items-per-page="-1" no-data-text="Nenhum item encontrado para os dados pesquisados ou realize a pesquisa...">
          <!-- Collapse dados-->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4 grey lighten-2  text-start"> 
              <PatientExpand :item="item" :modalidade_id="item.modalidade_id"/>
            </td>
          </template>
          <template v-slot:top>
            <v-row class="mt-2">
              <!-- Campo de Pesquisa -->
              <!-- <v-col cols="4">
                <v-text-field dense outlined v-model="search" label="Pesquisar" placeholder="Pesquise por Nome ou Operadora" class="mx-0" append-icon="mdi-magnify" clearable/>
              </v-col> -->
              <v-col class="text-end">
                <!-- Botão OFFLINE com confirmação -->
                <!-- <v-btn class="mr-4 elevation-0" text @click="dialog.confirmOffline = true" color="red"> OFFLINE </v-btn> -->
                <!-- Diálogo de Confirmação -->
                <v-dialog v-model="dialog.confirmOffline" max-width="600px">
                  <v-card>
                    <v-card-title class="headline">Confirmação</v-card-title>
                    <v-card-text>Tem certeza de que deseja atualizar as evoluções para OFFLINE = NÃO?</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="dialog.confirmOffline = false">Cancelar</v-btn>
                      <v-btn color="green darken-1" text @click="confirmOffline">Confirmar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                <!-- <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left> mdi-reload </v-icon> Atualizar </v-btn> -->
                <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left> mdi-plus </v-icon> Novo </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                      <v-icon color="green" size="28">mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar para Excel</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="elevation-0 ml-5" @click="openModal" v-bind="attrs" v-on="on" style="background-color: transparent;">
                        <v-icon color="green" size="28">mdi-file-document-check-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Imprimir Indicador Operadora</span>
                  </v-tooltip>
              </v-col>
              <!-- Relatórios -->      
              <div class="align-self-top py-4" left bottom v-if="selectedBudgets.length <= 0 || selectedBudgets.length >= 1 || shouldShowImpression || shouldShowPps || shouldShowCasa || shouldShowAbemid || shouldShowNead || shouldShowCaptacao || shouldShowContratoParticular">
                <v-menu eager offset-x left offset-y bottom v-model="menu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                      mdi-printer
                    </v-icon>
                  </template>
                  <v-container class="container">
                    <template v-if="selectedBudgets.length <= 0 ">
                      <RelatorioPaciente :displayedData="filteredRows" :username="lista.apelido"> </RelatorioPaciente>
                    </template>
                    <template v-if="shouldShowImpression">
                      <div >
                        <DeclaracaoAtendimento :selectedBudgets="selectedBudgets" :username="lista.apelido"> </DeclaracaoAtendimento>
                      </div>
                    </template>
                    <template v-if="shouldShowContratoParticular">
                      <div >
                        <ContratoParticular :selectedBudgets="selectedBudgets" :username="lista.apelido" ></ContratoParticular>
                      </div>
                    </template>
                    <template v-if="selectedBudgets.length >= 1">
                      <div >
                        <FichaSintetica :selectedBudgets="selectedBudgets" :username="lista.apelido"> </FichaSintetica>
                      </div>
                    </template>
                    <!-- Custom loading indicators for each section -->
                    <template v-if="isLoadingCasa">
                      <div class="loading-container">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        Carregando Avaliação Domiciliar...
                      </div>
                    </template>
                    <template v-else-if="shouldShowCasa">
                      <Casa_Cadastro :selectedBudgets="selectedBudgets" :username="lista.apelido"></Casa_Cadastro>
                    </template>
                    <template v-if="isLoadingCaptacao">
                      <div class="loading-container">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        Carregando Captação...
                      </div>
                    </template>
                    <template v-else-if="shouldShowCaptacao">
                      <Captacao_Cadastro :selectedBudgets="selectedBudgets" :username="lista.apelido"></Captacao_Cadastro>
                    </template>
                    <template v-if="isLoadingAbemid">
                      <div class="loading-container">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        Carregando ABEMID...
                      </div>
                    </template>
                    <template v-else-if="shouldShowAbemid">
                      <Abemid_Cadastro :selectedBudgets="selectedBudgets" :username="lista.apelido"></Abemid_Cadastro>
                    </template>
                    <template v-if="isLoadingNead">
                      <div class="loading-container">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        Carregando NEAD...
                      </div>
                    </template>
                    <template v-else-if="shouldShowNead">
                      <Nead_Cadastro :selectedBudgets="selectedBudgets" :username="lista.apelido"></Nead_Cadastro>
                    <template v-if="isLoadingPps">
                      <div class="loading-container">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        Carregando PPS...
                      </div>
                    </template>
                    <template v-else-if="shouldShowPps">
                      <Paliativo_Cadastro :selectedBudgets="selectedBudgets" :username="lista.apelido"></Paliativo_Cadastro>
                    </template>
                    </template>
                  </v-container>
                </v-menu>
              </div>
            </v-row>
            <v-row dense >
              <v-col  cols="1">
                <v-select hide-details :items="statusOptions"  label="Status" item-text="label" item-value="value" v-model="filters.status" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-select clearable hide-details :items="modalidadeOptions"  label="Modalidade" item-text="nome" item-value="id" v-model="filters.modalidade" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-select clearable hide-details :items="operadoraOptions" label="Operadora"  item-text="nome" item-value="id" v-model="filters.operadora" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-select clearable hide-details :items="statusOptions2"  label="Em Atendimento" item-text="label" item-value="value" v-model="filters.emAtendimento" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-select clearable hide-details :items="statusOptionsLiminar"  label="Liminar" item-text="label" item-value="value" v-model="filters.liminar" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-select clearable hide-details :items="statusOptionsPaliativo"  label="Paliativo" item-text="label" item-value="value" v-model="filters.paliativo" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-select clearable hide-details :items="statusOptionsRespirador"  label="Respirador" item-text="label" item-value="value" v-model="filters.respirador" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-text-field clearable label="Código Tablet"  type="Number" v-model="filters.codigo_legado" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-text-field clearable label="ID Paciente"  type="Number" v-model="filters.id" outlined dense />
              </v-col>
              <v-col  cols="2">
                <v-text-field clearable  label="Nome do Paciente" v-model="filters.paciente" outlined dense @input="formatPatientName"/>
              </v-col>
              <v-col class="text-end">
                <v-btn color="red" text class="mr-2" elevation="0" @click="clearFilters"> limpar </v-btn>
                <v-btn color="success" elevation="0" @click="applyFilters"> Pesquisar </v-btn>
              </v-col>
            </v-row>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="align-center">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <h3 class="blod_color">Perfil Rápido...</h3>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <h3 class="blod_color mb-5" style="text-align: center; width: 100%;"> QUANTIDADE DE PACIENTES</h3>
                  </v-row>
                  <v-row dense >
                    <v-col cols="2">
                      <div><span class="blod_color">Resumo de Qtd. Pacientes</span></div>
                      <div v-if="isLoading('get:patients')">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else>
                        <div><span>Total = {{ totalPacientesAtivos }} </span></div>
                        <div><span>Total em Atendimento = {{ totalPacientesAtivos - pacienteSemSessao }}<template v-if="(totalPacientesAtivos - pacienteSemSessao) > 0"> ({{ ((totalPacientesAtivos - pacienteSemSessao) / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span></div>
                        <div><span>- ID = {{ totalPacientesID }}<template v-if="totalPacientesID > 0"> ({{ (totalPacientesID / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span></div>
                        <div><span>- AD = {{ totalPacientesAD }}<template v-if="totalPacientesAD > 0"> ({{ (totalPacientesAD / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span></div>
                        <div><span>- Atendimento Suspenso = {{ totalPacientesSuspenso }}<template v-if="totalPacientesSuspenso > 0"> ({{ (totalPacientesSuspenso / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span></div>
                        <div><span>- Sem Orçamento = {{ pacienteSemSessao }}<template v-if="pacienteSemSessao > 0"> ({{ (pacienteSemSessao / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span></div>
                        <div><span>- Liminar = {{ pacienteLiminar }}</span></div>
                        <div><span>- Paliativo = {{ pacientePaliativo }}</span></div>
                        <div><span>- Respirador = {{ pacienteRespirador }}</span></div>
                      </div>
                    </v-col>

                    <v-col cols="2">
                      <span class="blod_color"><strong>Por Operadora</strong></span>
                      <div v-if="isLoading('get:patients')">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else>
                        <div v-for="(qtd, operadora) in sortedPacientesPorOperadora" :key="operadora">
                          <span>{{ operadora }} = {{ qtd }}<template v-if="qtd > 0"> ({{ ((qtd / totalPacientesAtivos) * 100).toFixed(2) }}%)</template></span>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="2">
                      <span class="blod_color"><strong>Por Modalidade</strong></span>
                      <div v-if="isLoading('get:patients')">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else>
                        <div v-for="(qtd, modalidade) in sortedPacientesPorModalidade" :key="modalidade">
                          <span>{{ modalidade }} = {{ qtd }}<template v-if="qtd > 0"> ({{ ((qtd / totalPacientesAtivos) * 100).toFixed(2) }}%)</template></span>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="2">
                      <span class="blod_color"><strong>Por Sexo Biológico</strong></span>
                      <div v-if="isLoading('get:patients')">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else>
                        <div>
                          <span>Masculino = {{ pacientesPorSexoBiologico.masculino }}<template v-if="pacientesPorSexoBiologico.masculino > 0"> ({{ (pacientesPorSexoBiologico.masculino / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span>
                        </div>
                        <div>
                          <span>Feminino = {{ pacientesPorSexoBiologico.feminino }}<template v-if="pacientesPorSexoBiologico.feminino > 0"> ({{ (pacientesPorSexoBiologico.feminino / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="2">
                      <span class="blod_color"><strong>Por Faixa Etária</strong></span>
                      <div v-if="isLoading('get:patients')">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else>
                        <div v-for="faixa in pacientesPorFaixaEtaria" :key="faixa.faixa">
                          <span>{{ faixa.faixa }} = {{ faixa.quantidade }}<template v-if="faixa.quantidade > 0"> ({{ (faixa.quantidade / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="2">
                      <h3 class="blod_color">Por Grupo de Diagnóstico</h3>
                      <div v-if="isLoading('get:patients')">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else>
                        <div v-for="cid in sortedCidData" :key="cid.nome">
                          <span>{{ cid.nome }}: {{ cid.quantidade }}<template v-if="cid.quantidade > 0"> ({{ (cid.quantidade / totalPacientesAtivos * 100).toFixed(2) }}%)</template></span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <!-- Editar um paciente -->
          <template v-slot:[`item.editar`]="{ item }">
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                  </template>
                  <span>Clique para editar</span>
              </v-tooltip>
          </template>
          <!-- Data de nasc -->
          <template v-slot:[`item.data_nascimento`]="{ item }">
            {{ (item.data_nascimento + "T00:00:00") | formatData }}
          </template>
          <template v-slot:[`item.idade`]="{ item }">
              {{  calculateAge(item.data_nascimento) }} anos
          </template >
          <template v-slot:[`item.cpf`]="{ item }">
              {{ item.cpf ? formatCPF(item.cpf) : ''}}
          </template>
          <template v-slot:[`item.telefone`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a :href="`https://wa.me/${item.telefone}`" target="_blank" class="whatsapp-link" v-bind="attrs" v-on="on">
                  <v-icon color="green">mdi-whatsapp</v-icon>
                  <span>{{ formatPhone(item.telefone) }}</span>
                </a>
              </template>
              <div>Chame no WhatsApp o(a)</div>
              <div>Responsável Sr.(a) {{item.responsavel}}</div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.liminar`]="{ item }">
            <span v-bind:style="{ color: item.liminar ? 'green' : 'red' }">
              {{ item.liminar ? 'Sim' : 'Não' }}
            </span>
          </template>
          <template v-slot:[`item.paliativo`]="{ item }">
            <span v-bind:style="{ color: item.paliativo ? 'green' : 'red' }">
              {{ item.paliativo ? 'Sim' : 'Não' }}
            </span>
          </template>
          <template v-slot:[`item.respirador`]="{ item }">
            <span v-bind:style="{ color: item.respirador ? 'green' : 'red' }">
              {{ item.respirador ? 'Sim' : 'Não' }}
            </span>
          </template>
          <template v-slot:[`item.modalidade_sessao`]="{ item }">
          <v-row class="d-flex justify-center align-center">
            <span v-if="item.orcamento_suspenso">
              <!-- Renderiza a mensagem e o nome da modalidade se orcamento_suspenso for True -->
              <span v-for="(i, k) in item.modalidade_sessao" :key="k">
                {{ i.nome }}
              </span>
              <span style="color: red; font-weight: bold;">
                Atendimento Suspenso
              </span>
            </span>
            <span v-else-if="item.modalidade_sessao && item.modalidade_sessao.length">
              <!-- Renderiza apenas a modalidade se não estiver suspenso -->
              <span v-for="(i, k) in item.modalidade_sessao" :key="k">
                {{ i.nome }}
              </span>
            </span>
            <span v-else :style="{ color: 'red', fontSize: '0.8rem', textAlign: 'center', display: 'block', width: '100%'  }">
              Sem Orçamento <br/>Aprovado
            </span>
          </v-row>
          </template>
          <!-- Geolocalizacao -->
          <template v-slot:[`item.geolocalizacao`]="{ item }">
            <v-row class="d-flex justify-center align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon 
                        @click="item.geolocalizacao ? onSelectRow(item, 'location') : null" 
                        :style="item.geolocalizacao ? '' : 'cursor: not-allowed;'" 
                        v-bind="attrs" v-on="on">
                    <v-icon :color="item.geolocalizacao ? 'green' : 'red'">mdi-map</v-icon>
                  </v-btn>
                </template>
                <span v-if="item.geolocalizacao">Clique aqui para olhar no mapa</span>
                <span v-else>Não tem geolocalização cadastrada ou ativa</span>
              </v-tooltip>
            </v-row>
          </template>
          <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox :input-value="selectedBudgets.includes(item)" @change="handleCheckboxChange(item)"></v-checkbox>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <IndicadorOperadora v-model="showModal" :showModal="showModal" @update:showModal="showModal = false"></IndicadorOperadora>
  <!-- Cadastro de Novo Paciente -->
      <v-dialog v-model="dialog.create">
        <v-card>
          <v-card-title class="sticky-title title-border">
          Cadastro de novo paciente:
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.create = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <DadosPessoais :currentItem="newItem" :onSubmit="postPatients" :loading="isLoading('post:patients')" />
        </v-card>
      </v-dialog>
  <!-- Edição de Paciente -->
      <FullscreenDialog v-model="dialog.update">
        <v-card>
          <v-card-title class="sticky-title title-border">
            Editar paciente: {{ selectedRow.nome ? selectedRow.nome : ''}} - ID: {{ selectedRow.id ? selectedRow.id : '' }}
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.update = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-tabs class="mb-4" v-model="currentTab">
            <v-tab>Dados Pessoais</v-tab>
            <v-tab v-show="pacientes_enderecos">Endereços</v-tab>
            <v-tab v-show="pacientes_equipe">Equipe</v-tab>
            <v-tab v-show="pacientes_geolocalizacao">Geolocalização</v-tab>
            <!-- <v-tab v-show="pacientes_equipamento">Equipamento</v-tab> -->
            <v-tab >Prescrição</v-tab>
            <v-tab>Troca de Dispositivo</v-tab>
            <!-- <v-tooltip top content-class="custom-tooltip-green">
             <template v-slot:activator="{ on, attrs }">
              <v-tab v-show="pacientes_exames" v-bind="attrs" v-on="on">Exames / Outros</v-tab>
            </template>
            <span>Exames e outros documentos. Aparecem no APP.</span>
            </v-tooltip> -->
            <v-tooltip top content-class="custom-tooltip-green">
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-show="pacientes_documentos" v-bind="attrs" v-on="on">Documentos</v-tab>
            </template>
            <span>Os Exames irão aparecer no APP.</span>
            </v-tooltip>
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <DadosPessoais :currentItem="selectedRow" :onSubmit="putPatients" :loading="isLoading('put:patients')" />
            </v-tab-item>
            <v-tab-item>
              <Enderecos :paciente="selectedRow" :onUpdate="getPatients" />
            </v-tab-item>
            <v-tab-item>
              <EquipeMultidisciplinar :paciente="selectedRow" :onUpdate="getPatients" />
            </v-tab-item>
            <v-tab-item>
              <Geolocalizacao :current-item="selectedRow" :onUpdate="getPatients" />
            </v-tab-item>
            <!-- <v-tab-item>
              <Equipamento :paciente="selectedRow" :onUpdate="getPatients" />
            </v-tab-item> -->
            <v-tab-item>
              <Prescricao :paciente="selectedRow" :onUpdate="getPatients" />
            </v-tab-item>
            <v-tab-item>
              <TrocaDispositivo :paciente="selectedRow" :onUpdate="putTrocaDispositivo"/>
            </v-tab-item>
            <!-- <v-tab-item>
              <Exames :paciente-id="selectedRow.id"/>
            </v-tab-item> -->
            <v-tab-item>
              <Documentos :paciente-id="selectedRow.id"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </FullscreenDialog>
  <!-- Localização do Paciente -->
      <v-dialog v-if="dialog.location" v-model="dialog.location">
        <v-card class="d-flex flex-column">
          <v-card-title class="blod_color">
            Localização paciente: {{ selectedRow.nome ? selectedRow.nome : ''}} - ID: {{ selectedRow.id ? selectedRow.id : '' }}
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.location = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="map-container d-flex">
            <MyMap :marca="selecteRowLocation" viewOnly />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import UtilsFunc from "../../service/utilsFunc";
import api from "../../http";
import DadosPessoais from "./componentes/pacientes/DadosPessoais.vue";
import Enderecos from "./componentes/pacientes/Enderecos.vue";
import EquipeMultidisciplinar from "./componentes/pacientes/EquipeMultisciplinar.vue";
import Geolocalizacao from "./componentes/pacientes/Geolocalizacao.vue";
// import Equipamento from "./componentes/pacientes/equipamento/Equipamento.vue";
import FullscreenDialog from "../FullscreenDialog.vue";
import MyMap from "../uteis/myMap.vue";
import PatientExpand from "./componentes/pacientes/PatientExpand.vue";
import { mapGetters } from 'vuex';
import './../../..../../assets/css/styles.css';
import Documentos from "./componentes/pacientes/Documentos/GestaoDocumentos.vue";
// import Exames from "./componentes/pacientes/Exame.vue";
import RelatorioPaciente from '../relatorios/RelatorioTelaPaciente.vue';
import DeclaracaoAtendimento from "../relatorios/documentosPaciente/DeclaracaoAtendimento.vue";
import Paliativo_Cadastro from '../relatorios/avaliacao/Paliativo_Cadastro.vue';
import Casa_Cadastro from '../relatorios/avaliacao/Casa_Cadastro.vue';
import Abemid_Cadastro from '../relatorios/avaliacao/Abemid_Cadastro.vue';
import Nead_Cadastro from '../relatorios/avaliacao/Nead_Cadastro.vue';
import Captacao_Cadastro from '../relatorios/avaliacao/Captacao_Cadastro.vue';
import FichaSintetica from '../relatorios/avaliacao/Ficha_Sintetica.vue';
import TrocaDispositivo from './componentes/pacientes/troca_Dispositivo/Troca.vue';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ContratoParticular from '../relatorios/documentosPaciente/ContratoParticular.vue';
import IndicadorOperadora from '../relatorios/indicadores/IndicadorOperadora.vue';
import Prescricao from '../prontuario/componentes/pacientes/prescricao/Index.vue';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "Prontuario",
  components: {
    DadosPessoais,
    EquipeMultidisciplinar,
    // Equipamento,
    Enderecos,
    Geolocalizacao,
    FullscreenDialog,
    MyMap,
    PatientExpand,
    Documentos,
    // Exames,
    RelatorioPaciente,
    DeclaracaoAtendimento,
    Paliativo_Cadastro,
    Casa_Cadastro,
    Abemid_Cadastro,
    Nead_Cadastro,
    Captacao_Cadastro,
    FichaSintetica,
    TrocaDispositivo,
    ContratoParticular,
    IndicadorOperadora,
    Prescricao,
},
  props: {
    id: { type: null },
  },
  data: () =>
    withCRUDUtils({
      currentTab: null,
      expanded: [],
      modalidadeOptions: [],
      operadoraOptions: [],
      qtd_sem_orcamento: 0,
      modalidade_id: '',
      newItem: {
        ativo: true,
        paliativo: false,
        respirador: false,
      },
      singleExpand: false,
      showFilters: false,
      dialog: { location: false, confirmOffline: false, create: false,},
      defaultSort: {
        isDesc: false,
        name: "nome",
      },
      statusOptions: [
        { label: "Ativo", value: 'True' },
        { label: "Inativo", value: 'False' },
      ],
      statusOptions2: [
        { label: "SIM", value: 'True' },
        { label: "NÃO", value: 'False' },
      ],
      statusOptionsLiminar: [
        { label: "SIM", value: 'True' },
        { label: "NÃO", value: 'False' },
      ],
      statusOptionsRespirador: [
        { label: "SIM", value: 'True' },
        { label: "NÃO", value: 'False' },
      ],
      statusOptionsPaliativo: [
        { label: "SIM", value: 'True' },
        { label: "NÃO", value: 'False' },
      ],
      filters: { status: 'True', modalidade:null, operadora:null, emAtendimento: null, nome: null, id: null, paliativo: null, liminar: null, respirador: null, codigo_legado: null},
      activeFilters: [],
      menu: false,
      headers: [
        { text: "Editar Paciente",  align: "center", value: "editar", width: 50, sortable: false, },
        { text: "Gerar Documento", align: "center", sortable: false, value: "select_budget" },
        { text: "Cód.Tablet", value: "codigo_legado" },
        { text: "Id", value: "id" },
        { text: "Nome", value: "nome", width: 250 },
        { text: "CPF", value: "cpf", width: 140 },
        { text: "Data nascimento", value: "data_nascimento" },
        { text: "Idade", value: "idade", sortable: true  },
        { text: "Telefone", value: "telefone" ,width: "200px"},
        { text: "Operadora", value: "operadora.nome" },
        { text: "Modalidade", align: "center", value: "modalidade_sessao",  tooltipMessage: "Sem Orçamento Aprovado", width: 100, },
        { text: "Geolocalização", align: "center", value: "geolocalizacao" },
        { text: "Liminar", align: "center", value: "liminar" },
        { text: "Paliativo", align: "center", value: "paliativo" },
        { text: "Respirador", align: "center", value: "respirador" },
      ],
      pacientes_enderecos: false,
      pacientes_equipe: false,
      pacientes_geolocalizacao: false,
      pacientes_equipamento: false,
      pacientes_exames: false,
      pacientes_documentos: false,
      selectedBudgets: [],
      pps: [],
      casa:[],
      abemid: [],
      nead: [],
      captacao:[],
      isLoadingPps: false,
      isLoadingCasa: false,
      isLoadingAbemid: false,
      isLoadingNead: false,
      isLoadingCaptacao: false,
      showModal: false, // Controle para exibir o modal de IndicadorOperadora
    }),
  computed: {
    ...mapGetters(['isSigned', 'companyData', 'userData']),
    filteredRows() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters)
      return this.tableData.filter(filterBy)
    },
    selecteRowLocation() {
      const [lat, lng, ] = (this.selectedRow.geolocalizacao || "").split(",");
      return { lat, lng, title: `Localização do Paciente` };
    },
    headersWithQuantity() {
      // Copie os cabeçalhos existentes
      const updatedHeaders = [...this.headers];

      // Encontre o cabeçalho da modalidade
      const modalidadeHeaderIndex = updatedHeaders.findIndex(header => header.value === 'modalidade_sessao');

      // Se encontrou, remova a quantidade existente entre parênteses, se houver
      if (modalidadeHeaderIndex !== -1) {
        updatedHeaders[modalidadeHeaderIndex].text = 'Modalidade';

        // Adicione a nova quantidade ao texto, se houver orçamentos não aprovados
        if (this.qtd_sem_orcamento > 0) {
          updatedHeaders[modalidadeHeaderIndex].text += ` (${this.qtd_sem_orcamento} Sem Orçamento Aprovado)`;
        }
      }

      return updatedHeaders;
    },
    shouldShowImpression() {
      return Array.isArray(this.selectedBudgets) && this.selectedBudgets.some(item => item.modalidade_sessao.length > 0 );
    },
    shouldShowContratoParticular() {
      return Array.isArray(this.selectedBudgets) && this.selectedBudgets.length > 0 && this.selectedBudgets[0] && this.selectedBudgets[0].operadora && this.selectedBudgets[0].operadora.id === 15;
    },
    shouldShowPps() {
      // Verifica se this.pps não está vazio, não contém null e tem pelo menos um objeto válido
      return Array.isArray(this.pps) && this.pps.length > 0 && this.pps[0] !== null;
    },
    shouldShowCasa() {
      // Verifica se this.pps não está vazio, não contém null e tem pelo menos um objeto válido
      return Array.isArray(this.casa) && this.casa.length > 0 && this.casa[0] !== null;
    },
    shouldShowAbemid() {
      // Verifica se this.pps não está vazio, não contém null e tem pelo menos um objeto válido
      return Array.isArray(this.abemid) && this.abemid.length > 0 && this.abemid[0] !== null;
    },
    shouldShowNead() {
      // Verifica se this.pps não está vazio, não contém null e tem pelo menos um objeto válido
      return Array.isArray(this.nead) && this.nead.length > 0 && this.nead[0] !== null;
    },
    shouldShowCaptacao() {
      // Verifica se this.pps não está vazio, não contém null e tem pelo menos um objeto válido
      return Array.isArray(this.captacao) && this.captacao.length > 0 && this.captacao[0] !== null;
    },
    lista() {
      return this.userData || {}
    },
    // Função que conta os pacientes por operadora
    pacientesPorOperadora() {
      const operadoraCount = {};

      this.tableData.forEach((paciente) => {
          const operadoraNome = paciente.operadora.nome;
          const orcamentoSuspenso = paciente.orcamento_suspenso === true;

          if (operadoraCount[operadoraNome]) {
            operadoraCount[operadoraNome].count++;
            if (orcamentoSuspenso) {
              operadoraCount[operadoraNome].orcamentoSuspenso++;
            }
          } else {
            operadoraCount[operadoraNome] = { count: 1, orcamentoSuspenso: orcamentoSuspenso ? 1 : 0 };
          }
      });

      return operadoraCount;
    },
    // Função que ordena as operadoras do maior para o menor
    sortedPacientesPorOperadora() {
      return Object.entries(this.pacientesPorOperadora)
        .sort(([, dataA], [, dataB]) => dataB.count - dataA.count)  // Ordena do maior para o menor com base no 'count'
        .reduce((obj, [operadora, data]) => {
          const { count, orcamentoSuspenso } = data;
          obj[operadora] = `${count} ${orcamentoSuspenso > 0 ? ` (Sendo ${orcamentoSuspenso} Orçamento Suspenso)` : ''}`;
          return obj;
        }, {});
    },
    // Função que conta os pacientes por modalidade
    pacientesPorModalidade() {
      const modalidadeCount = {};

      this.tableData.forEach((paciente) => {
        paciente.modalidade_sessao.forEach((modalidade) => {
          const modalidadeNome = modalidade.nome;
          const orcamentoSuspenso = paciente.orcamento_suspenso === true;

          if (modalidadeCount[modalidadeNome]) {
            modalidadeCount[modalidadeNome].count++;
            if (orcamentoSuspenso) {
              modalidadeCount[modalidadeNome].orcamentoSuspenso++;
            }
          } else {
            modalidadeCount[modalidadeNome] = { count: 1, orcamentoSuspenso: orcamentoSuspenso ? 1 : 0 };
          }
        });
        
      });

      return modalidadeCount;
    },

    // Função que ordena as modalidades em ordem alfabética e formata o resultado
    sortedPacientesPorModalidade() {
      return Object.entries(this.pacientesPorModalidade)
        .sort(([modalidadeA], [modalidadeB]) => modalidadeA.localeCompare(modalidadeB))
        .reduce((obj, [modalidade, data]) => {
          const { count, orcamentoSuspenso } = data;
          obj[modalidade] = `${count} ${orcamentoSuspenso > 0 ? ` (Sendo ${orcamentoSuspenso} Orçamento Suspenso)` : ''}`;
          return obj;
        }, {});
    },
    // Soma de todos os pacientes que têm modalidade_sessao com um objeto
    totalPacientesAtivos() {
      return this.tableData.length;
    },
    pacienteSemSessao() {
      return this.tableData.filter(paciente => {
        return !paciente.modalidade_sessao || paciente.modalidade_sessao.length === 0;
      }).length;
    },
    pacienteLiminar() {
      return this.tableData.filter((paciente) =>  paciente.liminar === true ).length;
    },
    pacienteRespirador() {
      return this.tableData.filter((paciente) =>  paciente.respirador === true ).length;
    },
    pacientePaliativo() {
      return this.tableData.filter((paciente) =>  paciente.paliativo === true ).length;
    },
    // Soma de pacientes onde o ID da modalidade_sessao é 1, 3, 4 ou 5
    totalPacientesID() {
      return this.tableData.filter((paciente) => 
        paciente.orcamento_suspenso === false && paciente.modalidade_sessao.some((modalidade) => [1, 3, 4, 5].includes(modalidade.id))
      ).length;
    },

    // Soma de pacientes onde o ID da modalidade_sessao é 2 (AD)
    totalPacientesAD() {
      return this.tableData.filter((paciente) => 
         paciente.orcamento_suspenso === false && paciente.modalidade_sessao.some((modalidade) => modalidade.id === 2)
      ).length;
    },
    totalPacientesSuspenso() {
      return this.tableData.filter((paciente) =>  paciente.orcamento_suspenso).length;
    },
    pacientesPorSexoBiologico() {
      const sexoBiologico = {
        masculino: 0,
        feminino: 0
      };

      this.tableData.forEach((paciente) => {
        if (paciente.sexo_biologico === 'MASCULINO') {
          sexoBiologico.masculino++;
        } else if (paciente.sexo_biologico === 'FEMININO') {
          sexoBiologico.feminino++;
        }
      });

      return sexoBiologico;
    },

    // Função que conta os pacientes por faixa etária
    pacientesPorFaixaEtaria() {
      const faixasEtarias = [
        { faixa: '0 - 09 anos', min: 0, max: 9 },
        { faixa: '10 - 19 anos', min: 10, max: 19 },
        { faixa: '20 - 29 anos', min: 20, max: 29 },
        { faixa: '30 - 39 anos', min: 30, max: 39 },
        { faixa: '40 - 49 anos', min: 40, max: 49 },
        { faixa: '50 - 59 anos', min: 50, max: 59 },
        { faixa: '60 - 69 anos', min: 60, max: 69 },
        { faixa: '70 - 79 anos', min: 70, max: 79 },
        { faixa: '80 anos ou mais', min: 80, max: 200 }
      ];

      const faixaEtariaContagem = faixasEtarias.map((faixa) => {
        const quantidade = this.tableData.filter((paciente) => {
          const idade = this.calculateAge(paciente.data_nascimento);
          return idade >= faixa.min && idade <= faixa.max;
        }).length;

        return { faixa: faixa.faixa, quantidade };
      });

      return faixaEtariaContagem;
    },
    cidData() {
      return [
        { nome: 'Mental', quantidade: this.tableData.filter(item => [99, 100, 101, 108, 114, 115, 121, 127, 133].includes(Number(item.cid.id))).length },
        { nome: 'Circulatório', quantidade: this.tableData.filter(item => [212, 213, 214, 218, 225, 229, 232, 234, 237, 239, 241].includes(Number(item.cid.id))).length },
        { nome: 'Digestivo', quantidade: this.tableData.filter(item => [299, 300, 312, 454].includes(Number(item.cid.id))).length },
        { nome: 'Endócrino', quantidade: this.tableData.filter(item => [94, 95, 1481, 98].includes(Number(item.cid.id))).length },
        { nome: 'Geniturinário', quantidade: this.tableData.filter(item => [412, 413, 420].includes(Number(item.cid.id))).length },
        { nome: 'Infecção', quantidade: this.tableData.filter(item => [23, 24, 22, 26, 37, 49].includes(Number(item.cid.id))).length },
        { nome: 'Neoplasia', quantidade: this.tableData.filter(item => [54, 1484, 56, 59, 60, 63, 65, 68, 73, 74, 76, 1485, 85, 86].includes(Number(item.cid.id))).length },
        { nome: 'Neurológico', quantidade: this.tableData.filter(item => [137, 138, 1482, 139, 1483, 140, 143, 157, 158, 159, 160].includes(Number(item.cid.id))).length },
        { nome: 'Osteomolecular', quantidade: this.tableData.filter(item => [349, 356, 357, 358, 360, 366, 375, 376, 380, 381, 384, 394, 396, 402, 409, 457, 481, 496, 506, 522, 526, 546, 556, 563].includes(Number(item.cid.id))).length },
        { nome: 'Pele', quantidade: this.tableData.filter(item => [339, 342, 535].includes(Number(item.cid.id))).length },
        { nome: 'Respiratório', quantidade: this.tableData.filter(item => [260, 269, 272, 273, 281, 282, 286].includes(Number(item.cid.id))).length }
      ];
    },
    // Função que ordena os grupos de diagnóstico do maior para o menor
    sortedCidData() {
      return [...this.cidData]  // Cria uma cópia do array original para evitar modificar os dados
        .sort((a, b) => b.quantidade - a.quantidade)  // Ordena do maior para o menor com base na 'quantidade'
        .map(cid => ({
          nome: cid.nome,
          quantidade: cid.quantidade
        }));
    }  
  },
  methods: {
    formatPatientName() {
    if (this.filters && this.filters.paciente) {
      const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
      this.filters.paciente = this.filters.paciente
        .toLowerCase()
        .split(" ")
        .map((word, index) => {
          // Mantém a primeira palavra em maiúsculas mesmo que esteja na lista
          if (index === 0 || !lowercaseWords.includes(word)) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }
          return word; // Retorna as palavras em minúsculas se estiverem na lista
        })
        .join(" ");
    }
  },
    async postPatients() {
      const LOADING_NAME = "post:patients";
      this.setLoading(LOADING_NAME);
      try {
        const fields = {
          ...this.newItem,
          multi_empresa: this.companyData.id
        }
        const response = await api.post("pacientes/listar/", fields);
        await this.getPatients();
        this.newItem = {
          ativo: true,
        };
        this.dialog.create = false;
        this.$toast.success("Paciente Criado com Ssucesso!");
        this.onSelectRow(response.data, 'update')
      } catch (error) {
        // this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async putPatients(fields) {
      const LOADING_NAME = "put:patients";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`pacientes/paciente/${this.selectedRow.id}/`, fields);
        await this.getPatients();
        this.collapseAll();
        // this.dialog.update = false;
        this.$toast.success("Paciente Atualizado com Sucesso!");
        this.atualizar;
      } catch (error) {
        // Verifica se a resposta contém dados específicos de erro
        if (error.response && error.response.data) {
          const errorMessage = Object.values(error.response.data).flat().join(', ');
          this.$toast.error(`Desculpe. Apresentou algum erro: ${errorMessage}`);
        } else {
          // Mensagem genérica de erro caso não haja dados de erro específicos
          this.$toast.error(`Desculpe. Apresentou algum erro.${error}`);
          // console.log(error)
        }
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async putTrocaDispositivo(fields) {
      const LOADING_NAME = "put:trocadispositivo";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`pacientes/troca-dispositivo/${this.selectedRow.id}/`, fields);
        await this.getPatients();
        this.collapseAll();
        this.dialog.trocadispositivo = false;
        this.$toast.success("Troca Cadastrada com Sucesso!");
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getPatients() {
      const LOADING_NAME = "get:patients";
      this.setLoading(LOADING_NAME);
      try {
        const params = `ativo=${this.filters.status}&operadora=${Number(this.filters.operadora)}&modalidade=${Number(this.filters.modalidade)}${this.filters.paciente ? `&paciente=${this.filters.paciente}` : ''}${this.filters.id ? `&id=${this.filters.id}` : ''}${this.filters.liminar ? `&liminar=${this.filters.liminar}` : ''}${this.filters.paliativo ? `&paliativo=${this.filters.paliativo}` : ''}${this.filters.respirador ? `&respirador=${this.filters.respirador}` : ''}${this.filters.codigo_legado ? `&codigo_legado=${this.filters.codigo_legado}` : ''}`;
        const idPaciente = this.id != null ? `id=${this.id}&` : ''
        const { data } = await api.get(`pacientes/listar/?${idPaciente}&${params}&empresa=${this.companyData.id}`);
        this.tableData = data;
        // console.log(this.tableData);

        // Aqui assumimos que cada paciente pode ter apenas um modalidade_sessao. 
        // Caso contrário, ajuste conforme necessário para iterar sobre os itens.
        this.tableData.forEach(item => {
          if (item.modalidade_sessao && item.modalidade_sessao.length > 0) {
            item.modalidade_id = item.modalidade_sessao[0].id;  // Supondo que há pelo menos um modalidade_sessao
          } else {
            item.modalidade_id = null;  // Certifique-se de que há um valor mesmo se não houver modalidade_sessao
          }
        });

        this.qtd_sem_orcamento = this.tableData.filter(item => item.modalidade_sessao && item.modalidade_sessao.length === 0).length;
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async applyFilters() {
      //if (!this.tableData[0] || this.tableData[0].ativo !== this.filters.status)
      this.search = ''
      this.selectedBudgets = ''
      await this.getPatients();
      if (this.filters.emAtendimento !== null) {
      this.tableData = this.tableData.filter(patient => {
        const hasModalidadeSessao = patient.modalidade_sessao && patient.modalidade_sessao.length > 0;
        return this.filters.emAtendimento === 'True' ? hasModalidadeSessao : !hasModalidadeSessao;
    });
  }
    },
    clearFilters() {
      this.activeFilters = []
      this.search = ''
      this.filters.modalidade = null
      this.filters.operadora = null
      this.filters.status = 'True'
      this.filters.emAtendimento = null;
      this.filters.paciente = null;
      this.filters.liminar = null;
      this.filters.respirador = null;
      this.filters.paliativo = null;
      this.filters.codigo_legado = null;
      this.filters.id = '';
      this.selectedBudgets = ''
      this.tableData = [];
      // this.getPatients()
    },
    // Método para recolher todoas as linhas expandidadas
    collapseAll() {
      this.expanded = [];
    },
    calculateAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },
    sortByAge() {
      if (this.defaultSort.name === 'idade') {
        this.tableData.sort((a, b) => {
          const ageA = this.calculateAge(a.data_nascimento);
          const ageB = this.calculateAge(b.data_nascimento);

          if (this.defaultSort.isDesc) {
            return ageB - ageA;
          } else {
            return ageA - ageB;
          }
        });
      }
    },
    async get_listas(url, lista){
      try {
        const response = await api.get(url)
        if(response.status === 200){
          // verifica se a resposta não é um array vazio
          if (response.data instanceof Array && response.data.length) {
            // ordena o array com base no campo 'nome'
            response.data.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });
          }
          this[lista] = response.data
        }
      } catch(error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    atualizar(){
      this.mounted()
    },
    formatPhone(phone) {
      if (!phone) return '';
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phone;
    },
    formatCPF(cpf) {
      if (!cpf) return '';
      const pattern = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
      return cpf.match(pattern) ? cpf.replace(pattern, "$1.$2.$3-$4") : 'Formato inválido';
    },
    async validaPermissao(sub_aba){
      try {
        const response = await api.get(`contas/permissoes-subaba/?sub_aba=${sub_aba}`)
        if(response.status === 200){
          this[sub_aba] = response.data.status
        }
      } catch(error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    async confirmOffline() {
      try {
        await this.putOffline(); // Chama o método que atualiza as evoluções
        this.dialog.confirmOffline = false; // Fecha o diálogo de confirmação
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }
    },
    async putOffline() {
      try {
        await api.put(`atendimentos/evolucao_offline/`);
        this.$toast.success("Evoluções OFFLINE atualizadas com sucesso!");
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }
    },
    handleCheckboxChange(item) {
      if (!Array.isArray(this.selectedBudgets)) {
        this.selectedBudgets = []; // Garante que selectedBudgets é um array
      }

      if (this.selectedBudgets.includes(item)) {
        this.selectedBudgets = []; // Se o item já estiver selecionado, desmarque-o.
      } else {
        this.selectedBudgets = [item]; // Selecione o novo item, desmarcando todos os outros.
      }
      this.getPps();
      this.getCasa();
      this.getAbemid();
      this.getCaptacao();
      this.getNead();
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredRows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Pacientes');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'pacientes.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
    async getPps() {
      this.isLoadingPps = true;
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&pps=true`);
        this.pps = data;
        const ppsDataWithValues = data.filter(item => item.pps && Object.keys(item.pps).length > 0);
        if (ppsDataWithValues.length > 0) {
          const latestPpsData = ppsDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });
          this.pps = [latestPpsData];
          // console.log('PPS -> ', this.pps)
        } else {
          this.pps[0] = null;
          // console.log('PPS -> ', this.pps)
        }
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados do PPS:", error);
      } finally {
        this.isLoadingPps = false;
      }
    },
    async getCasa() {
      this.isLoadingCasa = true;
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&casa=true`);
        this.casa = data;
        const casaDataWithValues = data.filter(item => item.casa && Object.keys(item.casa).length > 0);
        if (casaDataWithValues.length > 0) {
          const latestcasaData = casaDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });
          this.casa = [latestcasaData];
          // console.log('casa -> ', this.casa)
        } else {
          this.casa[0] = null;
          // console.log('casa -> ', this.casa)
        }
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados do casa:", error);
      } finally {
        this.isLoadingCasa = false;
      }
    },
    async getAbemid() {
      this.isLoadingAbemid = true;
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&abemid=true`);
        this.abemid = data;
        const casaDataWithValues = data.filter(item => item.abemid && Object.keys(item.abemid).length > 0);
        if (casaDataWithValues.length > 0) {
          const latestcasaData = casaDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });
          this.abemid = [latestcasaData];
          // console.log('abemid -> ', this.abemid)
        } else {
          this.abemid[0] = null;
          // console.log('abemid -> ', this.abemid)
        }
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados do abemid:", error);
      } finally {
        this.isLoadingAbemid = false;
      }
    },
    async getNead() {
      this.isLoadingNead = true;
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&nead=true`);
        this.nead = data;
        const casaDataWithValues = data.filter(item => item.nead && Object.keys(item.nead).length > 0);
        if (casaDataWithValues.length > 0) {
          const latestcasaData = casaDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });
          this.nead = [latestcasaData];
          // console.log('nead -> ', this.nead)
        } else {
          this.nead[0] = null;
          // console.log('nead -> ', this.nead)
        }
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados do nead:", error);
      } finally {
        this.isLoadingNead = false;
      }
    },
    async getCaptacao() {
      this.isLoadingCaptacao = true;
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&captacao=true`);
        this.captacao = data;
        const casaDataWithValues = data.filter(item => item.captacao && Object.keys(item.captacao).length > 0);
        if (casaDataWithValues.length > 0) {
          const latestcasaData = casaDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });
          this.captacao = [latestcasaData];
          // console.log('captacao -> ', this.captacao)
        } else {
          this.captacao[0] = null;
          // console.log('captacao -> ', this.captacao)
        }
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados do captacao:", error);
      } finally {
        this.isLoadingCaptacao = false;
      }
    },
     openModal() {
      this.showModal = true; // Abre o modal
    },
    closeModal() {
      this.showModal = false; // Fecha o modal
    },
    pesquisar() {
      this.applyFilters()
    },
  },
  watch: {
    "dialog.update"() {
      if (!this.dialog.update) this.selectedRow = {};
    },
    'defaultSort.name'(newSort) {
      if (newSort === 'idade') {
        this.sortByAge();
      }
    },
    'defaultSort.isDesc'() {
      this.sortByAge();
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const pacienteId = urlParams.get('id');
    
    if (pacienteId) {
      // Define o filtro inicial com o ID do paciente
      this.filters.id = pacienteId;
      
      // Aplica os filtros para carregar os dados do paciente
      this.applyFilters();
    }
    this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
    this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
    
    this.validaPermissao('pacientes_enderecos')
    this.validaPermissao('pacientes_equipe')
    this.validaPermissao('pacientes_geolocalizacao')
    this.validaPermissao('pacientes_equipamento')
    this.validaPermissao('pacientes_exames')
    this.validaPermissao('pacientes_documentos')
    // if (!this.companyData) return
    // (async () => {
    //   await this.getPatients();
    //   if (this.id) {
    //     const paramId = Number(this.id)
    //     const filteredRow = this.tableData.find(item => item.id === paramId)
    //     if (!filteredRow) return
    //     this.activeFilters.push({
    //       prop: 'id',
    //       compareType: 'equal',
    //       value: paramId
    //     })
    //     this.dialog.update = true
    //     this.dialog.trocadispositivo = true
    //     this.selectedRow = filteredRow
    //   }
    // })()
    this.getPps()
    this.getCasa()
    this.getAbemid()
    this.getCaptacao()
    this.getNead()

  },
};
</script>

<style scoped>
div.map-container {
  position: relative;
  height: 500px;
}
.custom-tooltip-green {
    background-color: green !important;
    color: white !important;
} 
.title-border {
  border-bottom: 2px solid #E0E0E0; /* ajuste a cor conforme necessário */
  padding-bottom: 10px;
}
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: normal;
}

</style>
