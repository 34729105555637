<template>
    <v-container fluid class="gestao-documentos">
        <v-row dense class="my-3 text-center">
            <v-col cols="2">
                <h3 class="blod_color mt-1 ">Carregar documento:</h3>
            </v-col>
            <v-col cols="3">
                <v-autocomplete outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome" item-value="id">
                <template v-slot:label>
                    <span>Tipo de documento <span style="color: red;">*</span></span>
                </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4">
                <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput">
                <template v-slot:label>
                    <span>Anexar documento <span style="color: red;">*</span></span>
                </template>
                </v-file-input>
            </v-col>
            <v-col cols="1">
                <v-tooltip top :disabled="!!(selectedDocumentType && selectedFile)">
                <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                    <v-btn @click="uploadDocument" :color="isFormValid ? 'success' : 'grey'" :loading="isUploading" :disabled="!isFormValid || isUploading">
                        <v-icon left>mdi-upload</v-icon>
                        Enviar Documento
                    </v-btn>
                    </span>
                </template>
                <span>Escolha o "Tipo de documento" e "Pasta"e carregue o arquivo para habilitar este botão.</span>
                </v-tooltip>
            </v-col>
            <v-col cols="2" >
                <v-btn color="red" text class="mr-2" elevation="0" @click="atualizar" > limpar </v-btn>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" show-arrows>
            <v-tab><v-icon left>mdi-file-document</v-icon> ABEMID - NEAD</v-tab>
            <v-tab><v-icon left>mdi-hospital</v-icon> Admissão</v-tab>
            <v-tab><v-icon left>mdi-skull</v-icon> Atestado de Óbito</v-tab>
            <v-tab><v-icon left>mdi-file-chart</v-icon> Captação</v-tab>
            <v-tab><v-icon left>mdi-stethoscope</v-icon> Evolução</v-tab>
            <v-tab><v-icon left>mdi-test-tube</v-icon> Exames</v-tab>
            <v-tab><v-icon left>mdi-alert-circle</v-icon> Intercorrência</v-tab>
            <v-tab><v-icon left>mdi-hammer-wrench</v-icon> Procedimentos</v-tab>
            <v-tab><v-icon left>mdi-ambulance</v-icon> Remoção</v-tab>
            <v-tab><v-icon left>mdi-cash-register</v-icon> Orçamentos</v-tab>
            <v-tab><v-icon left>mdi-medical-bag</v-icon> Prescrição</v-tab>
            <v-tab><v-icon left>mdi-file-document-box</v-icon> Guias Atendimentos</v-tab>
            <v-tab><v-icon left>mdi-file-document-box</v-icon> Relatórios</v-tab>
            <v-tab><v-icon left>mdi-file-document-box</v-icon> Prot. Entrega</v-tab>
            <v-tab><v-icon left>mdi-file-document-box</v-icon> Prot. Devolução</v-tab>
            <v-tab><v-icon left>mdi-identifier</v-icon> Placa Identificação</v-tab>
            <v-tab><v-icon left>mdi-file-document-box</v-icon> Diversos</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <AbemidNead :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Admissao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <AtestadoObito :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Captacao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Evolucao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Exame :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Intercorrencia :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Procedimentos :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Remocao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Orcamentos :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Prescricao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <GuiasAtendimentos :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Relatorios :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <ProtocoloEntrega :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <ProtocoloDevolucao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <PlacaIdentificacao :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
            <v-tab-item>
                <Diversos :pacienteId="pacienteId" ref="atualizando"/>
            </v-tab-item>
           
        </v-tabs-items>
    </v-container>
</template>

<script>
import '../../../../../assets/css/styles.css'
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc;

export default {
    name: 'GestaoEquipamento',
    components: {
    Exame: () => import("./Exames.vue"),//
    AbemidNead: () => import("./AbemidNead.vue"), //
    Admissao: () => import("./Admissao.vue"),//
    AtestadoObito: () => import("./AtestadoObito.vue"),//
    Captacao: () => import("./Captacao.vue"),//
    Diversos: () => import("./Diversos.vue"),//
    Evolucao: () => import("./Evolucao.vue"),//
    GuiasAtendimentos: () => import("./GuiasAtendimentos.vue"),
    Intercorrencia: () => import("./Intercorrencia.vue"),//
    Orcamentos: () => import("./Orcamentos.vue"),//
    PlacaIdentificacao: () => import("./PlacaIdentificacao.vue"),//
    Prescricao: () => import("./Prescricao.vue"),//
    Procedimentos: () => import("./Procedimento.vue"),//
    ProtocoloDevolucao: () => import("./ProtocoloDevolucao.vue"),//
    ProtocoloEntrega: () => import("./ProtocoloEntrega.vue"),//
    Relatorios: () => import("./Relatorios.vue"),//
    Remocao: () => import("./Remocao.vue"),//

},
     data: () => withCRUDUtils({
        tab: 0,
        subTab:0,
        selectedDocumentType: null,
        documentTypes: [],
        selectedPastaType: null,
        uploadedDocuments: [],
        isUploading: false,
        isActive: true,
        selectedFile: null,
    }),
    props: {
        pacienteId: { type: Number }
    },
    methods: {
        async getDocumentTypes() {
            const LOADING_NAME = "get:documentTypes";
            this.setLoading(LOADING_NAME);
            try {
                const { data } = await api.get("/uteis/tiposdocs/");
                this.documentTypes = data
                    .filter(doctype => doctype.ativo && [16, 20, 21, 22, 23, 24, 27, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, ].includes(doctype.id));
            } catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async uploadDocument() {
            if (!this.selectedDocumentType || !this.selectedFile) {
                this.$toast.error("Por favor, selecione o tipo de documento e o arquivo.");
                return;
            }

            this.isUploading = true;

            // Definir o endpoint baseado no tipodoc
            const isExame = this.selectedDocumentType === 17;
            const endpoint = isExame ? "/pacientes/exames/" : "/pacientes/docs/";

            // Mapeamento de tipodoc para tipopasta (somente se NÃO for exame)
            const tipopastaMapping = {
                16: 2,
                20: 9,
                21: 11,
                22: 11,
                23: 10,
                24: 10,
                27: 16, 
                32: 1,
                33: 1,
                34: 6,
                35: 6,
                36: 6,
                37: 2,
                38: 3,
                41: 16,
                42: 16,
                43: 16,
                44: 2,
                45: 2,
                46: 2,
                47: 2,
                48: 5,
                49: 7, 
                50: 12,
                51: 13,
                52: 14,
                53: 15,
            };
            let formData = new FormData();
            formData.append("arquivo", this.selectedFile);
            formData.append("tipodoc", this.selectedDocumentType);
            formData.append("ativo", this.isActive);
            formData.append("paciente", this.pacienteId);

            // Se NÃO for exame, adiciona tipopasta
            if (!isExame) {
                const tipopastaValue = tipopastaMapping[this.selectedDocumentType] || 1;
                formData.append("tipopasta", tipopastaValue);
            }

            // Data de carregamento
            const currentDate = new Date().toISOString().split('T')[0];
            formData.append("data_carregado", currentDate);

            try {
                await api.post(endpoint, formData);
                this.$toast.success("Documento carregado com sucesso!");
                this.selectedFile = null;
                this.selectedDocumentType = null;
                this.selectedPastaType = null;
                this.fetchUploadedDocuments();
                this.getDocumentTypes();
                // Atualiza todas as abas
                this.atualizarTodasAsAbas();

            } catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
            } finally {
                this.isUploading = false;
            }
        },
        async fetchUploadedDocuments() {
            this.isUploading = true;
            try {
                const { data } = await api.get(`/pacientes/docs/?paciente=${this.pacienteId}`);
                this.uploadedDocuments = data.sort((a, b) => new Date(b.data_carregado) - new Date(a.data_carregado));
            } catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os documentos. Erro: ${error}`);
            } finally {
                this.isUploading = false;
            }
        },
        extractFileName(url) {
            if (url) {
                return url.split('/').pop();
            }
            return "";
        },
        checkFileSize() {
            if (this.selectedFile && this.selectedFile.size > 2000000) { // maior que 2MB
                this.$toast.error("O tamanho do arquivo é maior do que o permitido (2MB).");
                this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
                this.$refs.fileInput.reset();
            }
            },
        formatDate(dateString) {
            if (!dateString) return '';
            const [year, month, day] = dateString.split('-');
            return `${day}/${month}/${year}`;
        },
        created() {
            this.getDocumentTypes();
            this.getPastaTypes();
            },
       
        inicializar(){
            this.fetchUploadedDocuments();
            this.getDocumentTypes();
            // this.getPastaTypes()
        },
        atualizar(){
            this.inicializar()
            this.selectedDocumentType = null
            this.selectedPastaType = null
            this.selectedFile = null
        },
        atualizarTodasAsAbas() {
            // Percorre todas as abas e chama `fetchUploadedDocuments()`, se disponível
            Object.values(this.$refs).forEach(ref => {
                if (ref && typeof ref.fetchUploadedDocuments === "function") {
                    ref.fetchUploadedDocuments();
                }
            });
        },

    },
    computed: {
        isFormValid() {
        return this.selectedDocumentType && this.selectedFile;
        },
        requiredAsterisk() {
        return '<span style="color: red;">*</span>';
        },
    },
    mounted() {
        this.inicializar()
    },
}
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

.v-tabs {
  font-size: 12px !important; /* Reduz o tamanho do texto nas abas */
  min-height: 32px; /* Reduz a altura das abas */
}

.v-tab {
  font-size: 12px !important;
  padding: 4px 8px !important; /* Reduz o espaçamento interno */
  min-height: 32px !important;
}

.v-tab .v-icon {
  font-size: 16px !important; /* Reduz o tamanho dos ícones */
  margin-right: 4px;
}

.v-tab--active {
  background-color: #1976D2 !important;
  color: white !important;
}

img {
  max-width: 100px; /* Ajuste conforme necessário */
  height: auto;
}

.v-btn {
  font-size: 12px !important;
  height: 32px !important; /* Reduz a altura */
  padding: 0 10px !important; /* Reduz o padding interno */
}
.v-autocomplete,
.v-file-input {
  font-size: 12px !important; /* Reduz o tamanho da fonte dentro dos campos */
  height: 36px !important; /* Reduz a altura do campo */
}

.v-input__control {
  min-height: 36px !important;
}


</style>