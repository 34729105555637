<template>
  <div class="px-4 pb-4">
    <v-row dense>
      <v-col>
        <v-data-table
          :loading="isLoadingEsterelizavel" loading-text="Carregando Esterilizáveis...." :search="search" :headers="headers" :items="filteredItems" dense v-model="selectedItemIds"  item-key="id" show-select :items-per-page="-1" >
          <template v-slot:top>
            <!-- Linha de Pesquisar, atualizar e criar nova solicitação equipamento -->
            <v-row class="mt-4" dense>
              <!-- Campo de Pesquisa do endereço -->
              <v-col cols="2">
                <v-text-field dense label="Pesquisar" outlined v-model="search" class="mx-0" append-icon="mdi-magnify" clearable />
              </v-col>
              <v-col class="text-end">
                <v-badge left bordered overlap :content="activeFilters.length">
                  <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                  <v-icon left>  mdi-filter </v-icon> Filtros </v-btn>
                </v-badge>
                <!-- Botão de Atualizar a tela -->
                <v-btn class="mr-4 elevation-0" text  @click="atualizar" > <v-icon left> mdi-reload </v-icon> atualizar</v-btn>
                <ConfirmButton  color="primary"  class="mr-4 elevation-0"   :disabled="!isRetirarHabilitado"   :loading="isLoadingRetirar"  :onConfirm="retirarTodos">
                  <div class="d-flex flex-column compact-btn-text"> <span>Solicitar</span> <span>Retirada</span> </div> 
                </ConfirmButton>
                <ConfirmButton  color="primary"  class="mr-4 elevation-0"   :disabled="!isRetirarHabilitado"   :loading="isLoadingTroca"  :onConfirm="TrocaTodos">
                  <div class="d-flex flex-column compact-btn-text"> <span>Solicitar</span> <span>Troca</span> </div> 
                </ConfirmButton>
                <ConfirmButton  color="primary"  class="mr-4 elevation-0"   :disabled="!isCancelar"   :loading="isLoadingRetirada"  :onConfirm="cancelarRequiscoes">
                  <div class="d-flex flex-column compact-btn-text"> <span>Cancelar</span> <span>Requisições</span> </div> 
                </ConfirmButton>
                
                <!-- Botão de Criar "+ NOVO" -->
                <v-btn color="success" class="elevation-0"  @click="dialog.create = true" > <v-icon left> mdi-plus </v-icon> NOVO </v-btn>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-card outlined v-show="showFilters" dense>
              <h4 class="ml-4 mt-4">Filtrar por</h4>
              <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                  <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                      v-model="filters.is_active.value" outlined dense />
                  </v-col>
              </v-row>
              <v-card-actions>
                  <v-row dense>
                  <v-col class="text-end">
                      <v-btn color="error" text elevation="0" @click="clearFilters">
                      Limpar filtros
                      </v-btn>
                      <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                      Aplicar filtros
                      </v-btn>
                  </v-col>
                  </v-row>
              </v-card-actions>
              </v-card>
          </v-expand-transition>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
          <!-- Adicione este template slot junto com os outros -->
          <template v-slot:[`item.frequencia`]="{ item }">
            <span>{{ getFrequenciaTroca(item.dias_troca) }}</span>
          </template>
          <template v-slot:[`item.disponibilidade`]="{ item }">
            <span>{{ getNomeDisponibilidade(item.disponibilidade) }}</span>
          </template>
          <template v-slot:[`item.data_inclusao_orcamento`]="{ item }">
            <span>{{ formatDate(item.data_inclusao_orcamento) }}</span>
          </template>
          <template v-slot:[`item.data_requisicao`]="{ item }">
            <span>{{ formatDate(item.data_requisicao) }} / {{formatDate(item.data_prevista_implantacao)}}</span>
          </template>
          <!-- <template v-slot:[`item.data_prevista_implantacao`]="{ item }">
            <span>{{ formatDate(item.data_prevista_implantacao) }}</span>
          </template> -->
          <template v-slot:[`item.data_implantacao`]="{ item }">
            <span>{{ formatDate(item.data_implantacao) }}</span>
          </template>
          <template v-slot:[`item.data_solicitacao_retirada`]="{ item }">
            <span> {{formatDate(item.data_solicitacao_retirada) || formatDate(item.data_solicitacao_troca)}} / {{ formatDate(item.data_prevista_solicitacao_retirada) || formatDate(item.data_prevista_solicitacao_troca)}}</span>
          </template>
          <template v-slot:[`item.data_retirada`]="{ item }">
            <span>{{ formatDate(item.data_retirada) }} {{ formatDate(item.data_troca) }}</span>
          </template>
          <template v-slot:no-data>
            <span> Nenhum equipamento encontrado. </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <span>  
              <v-btn icon @click="onSelectEsterelizavel(item, 'update')">
                <v-icon color="green">mdi-pencil</v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-col>
<!-- Novo Esterilizável -->
      <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="90%" @input="handleDialogClose" >
        <v-card>
          <v-card-title class="blod_color">
            Novo Esterilizável:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de solicitação de novo equipamento -->
          <v-card-text>
            <NovoEquipamento 
              :paciente_id="paciente.id"  
              :onSubmit="saveEsterilizavel" 
              :loading="isLoading('post:esterilizavel')"  />
          </v-card-text>
        </v-card>
      </v-dialog>
<!-- Editar um Esterilizavel -->
      <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="1200" @input="handleDialogClose" >
        <v-card>
          <v-card-title class="blod_color"> 
            Editar Esterilizável:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de edição de equipamento -->
          <v-card-text>
            <EditarEquipamento 
              :esterilizavel="selectedEsterilizavel" 
              :onSubmit="updateEsterilizavel" 
              :loading="isLoading('put:esterilizavel')"  
              :ativoOptions="ativoOptions"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '@/service/utilsFunc'
import NovoEquipamento from "./Novo.vue";
import EditarEquipamento from "./Editar.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";


const { withCRUDUtils } = UtilsFunc

export default {
  name: "EquipamentoPaciente",
  components: { NovoEquipamento, EditarEquipamento, ConfirmButton  },
  props: {
    paciente: {
      type: Object
    },
    onUpdate: Function
  },
  data() {
    return withCRUDUtils({
      esterilizaveisPaciente: [],
      isLoadingDisponibilidade: false,
      isLoadingEsterelizavel: false,
      isLoadingRetirar: false,
      isLoadingTroca: false,
      isLoadingRetirada: false,
      isLoaded: false, // Nova propriedade
      dialog: {
        create: false,
        update: false
      },
      ativoOptions: [
        { nome: "Ativo", value: true },
        { nome: "Inativo", value: false },
      ],
      statusOptions: [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false }
      ],
      activeFilters: [],
      showFilters: false,
      disponibilidade: [],
      filters: {
        is_active: { value: true, compareType: 'equal', prop: 'ativo' }
      },  
      search: '',
      selectedEsterilizavel: null,
      selectedItemIds: [],
      headers: [
        { text: "Editar", value: "edit", width: 80 },
        { text: "Id Solicitação", value: "id", width: 80 },
        { text: "Id Esterilizável", value: "esterilizavel.id", width: 80 },
        { text: "Esterilizável", value: "nome_esterilizavel.nome", width: 250 },
        { text: "Disponibilidade", value: "disponibilidade.nome" },
        { text: "Data da Requisição / Previsão", value: "data_requisicao" },
        { text: "Frenquência de Requisição de Troca", value: "frequencia.nome" },
        { text: "Data da Implantação", value: "data_implantacao" },
        { text: "Data Solicitação Troca ou Retirada / Previsão", value: "data_solicitacao_retirada" },
        { text: "Data da Troca ou Retirada", value: "data_retirada" },
        { text: "Observação", value: "obs" },
        { text: "Status", value: "ativo" }
      ],
    });
  },
  methods: {
    async getEsterilizaveisPaciente() {
      this.isLoadingEsterelizavel = true
      try {
        const { data } = await api.get(`/pacientes/esterilizaveis/?paciente=${this.paciente.id}`)
        this.esterilizaveisPaciente = data.sort((a, b) => {
          // .filter(item => item.ativo === true)
          if (a.ativo !== b.ativo) {
                  return b.ativo - a.ativo;
              }

              // Ordenação por `disponibilidade.id` na ordem específica
              const ordemDisponibilidade = [14, 13, 12, 4, 3, 2, 1];
              const indexA = ordemDisponibilidade.indexOf(a.disponibilidade?.id) !== -1 
                  ? ordemDisponibilidade.indexOf(a.disponibilidade.id) 
                  : ordemDisponibilidade.length;
              const indexB = ordemDisponibilidade.indexOf(b.disponibilidade?.id) !== -1 
                  ? ordemDisponibilidade.indexOf(b.disponibilidade.id) 
                  : ordemDisponibilidade.length;

              if (indexA !== indexB) {
                return indexA - indexB;
            }

            // Ordenação por `nome_equipamento.nome` (alfabeticamente)
              const nomeA = a.nome_esterilizavel?.nome?.toLowerCase() || '';
              const nomeB = b.nome_esterilizavel?.nome?.toLowerCase() || '';
              return nomeA.localeCompare(nomeB);
          });
        // console.log('Esterilizaveis do Paciente--> ', this.esterilizaveisPaciente)
        this.isLoaded = true 
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingEsterelizavel = false
      }
    },
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        // Filtra apenas as disponibilidades ativas e ordena por ID
        this.disponibilidade = data
          .filter(item => item.ativo)
          .sort((a, b) => a.id - b.id);
        // console.log('Disponibilidades carregadas:', this.disponibilidade);
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado ao carregar disponibilidades. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    async updateEsterilizavel(fields) {
      const LOADING_NAME = 'put:esterilizavel'
      this.setLoading(LOADING_NAME)
      try {
        // console.log('onSubmit --> ',fields)
        await api.put(`pacientes/esterilizavel/${fields.id}/`, fields)
        await this.getEsterilizaveisPaciente()
        this.$toast.success('Esterilizável alterado com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.dialog.update = false
      }
    },
    async saveEsterilizavel(field) {
      const LOADING_NAME = 'post:esterilizavel';
      this.setLoading(LOADING_NAME);
      try {
        // console.log('Aqui o que foi--> ',field)
        for (const esterilizaveis of field) {
          await api.post(`/pacientes/esterilizaveis/`, esterilizaveis);
        }
        this.$toast.success('Esterilizável(s) salvo(s) com sucesso!');
        await this.getEsterilizaveisPaciente();
        this.dialog.create = false;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
        this.dialog.create = false;
      }
    },
    async applyFilters() {
      this.search = '';
      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters)
        .filter(filter => filter.value !== undefined)
        .map(filter => ({
          value: filter.value,
          compareType: filter.compareType,
          prop: filter.prop
        }));
    },
    clearFilters() {
      this.filters = {
        is_active: { value: true, compareType: 'equal', prop: 'ativo' }
      };
      this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
      this.search = '';
    },
    onSelectEsterelizavel(esterilizaveisPaciente, action) {
      if (action === 'update') {
        this.selectedEsterilizavel = esterilizaveisPaciente;
        this.dialog.update = true;
      }
    },
    formatDate(isoString) {
      if (!isoString) return '';
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste para compensar a diferença do fuso horário
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async atualizar() {
      this.isLoaded = false;
      this.selectedItemIds = [],
      this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
      this.showFilters = false;
      await this.getEsterilizaveisPaciente()
    },
    handleDialogClose(val) {
        if (!val) { // quando o diálogo fecha, val será false
          this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
          this.showFilters = false;

          this.atualizar();
        }
        this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
        this.showFilters = false;
        this.atualizar();
    },
    closeDialog() {
      this.dialog.create = false;
      this.dialog.update = false;
      this.atualizar();
    },
    getNomeDisponibilidade(id) {
      if (!id) return '';
      const disponibilidadeEncontrada = this.disponibilidade.find(d => d.id === id);
      return disponibilidadeEncontrada ? disponibilidadeEncontrada.nome : `Disponibilidade ${id}`;
    },
    getFrequenciaTroca(dias) {
      if (!dias) return 'Sem programação';
      
      const frequencias = {
        7: 'Semanal',
        15: 'Quinzenal',
        30: 'Mensal',
        90: 'Trimestral',
        180: 'Semestral'
      };

      return frequencias[dias] || `${dias} dias`;
    },
    async retirarTodos() {
      this.isLoadingRetirar = true;
      try {
      const hoje = new Date().toISOString().split('T')[0];
      const amanha = new Date();
      amanha.setDate(amanha.getDate() + 1);
      const dataAmanha = amanha.toISOString().split('T')[0];
      const updatePromises = this.selectedItemIds.map(async (item) => {
        await api.put(`pacientes/esterilizavel/${item.id}/`, {
          id: item.id,
          disponibilidade: 12,
          data_solicitacao_retirada: hoje,
          data_prevista_solicitacao_retirada: dataAmanha,
          requisicao: null,
          troca: null,
          retirada: true,
        });
        await api.put(`/equipamentos/esterelizavel_editar/${item.esterilizavel.id}/`, {
            id: item.esterilizavel.id,
            disponibilidade: 12
          });
        this.atualizar()
      });

      await Promise.all(updatePromises);
      
      this.$toast.success('Esterilizáveis alterados com sucesso!');
      this.atualizar()
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os esterilizáveis. ${error}`);
      } finally {
        this.isLoadingRetirar = false;
        this.selectedItemIds = [];
        await this.getDisponibilidade();
        this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
        await this.getEsterilizaveisPaciente();
        this.atualizar()
      }
    },
    async TrocaTodos() {
      this.isLoadingRetirar = true;
      try {
      const hoje = new Date().toISOString().split('T')[0];
      const amanha = new Date();
      amanha.setDate(amanha.getDate() + 1);
      const dataAmanha = amanha.toISOString().split('T')[0];
      const updatePromises = this.selectedItemIds.map(async (item) => {
        await api.put(`pacientes/esterilizavel/${item.id}/`, {
          id: item.id,
          disponibilidade: 4,
          data_solicitacao_troca: hoje,
          data_prevista_solicitacao_troca: dataAmanha,
          requisicao: null,
          troca: true,
          retirada: null,
        });
        await api.put(`/equipamentos/esterelizavel_editar/${item.esterilizavel.id}/`, {
            id: item.esterilizavel.id,
            disponibilidade: 4
          });
        this.atualizar()
      });

      await Promise.all(updatePromises);
      
      this.$toast.success('Esterilizáveis alterados com sucesso!');
      this.atualizar()
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os esterilizáveis. ${error}`);
      } finally {
        this.isLoadingRetirar = false;
        this.selectedItemIds = [];
        // await this.getDisponibilidade();
        this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
        // await this.getEsterilizaveisPaciente();
        this.atualizar()
      }
    },
    async cancelarRequiscoes() {
      this.isLoadingRetirada = true;
      try {
      // const today = new Date().toISOString().split('T')[0];
      // // Processamento diferenciado baseado na disponibilidade
      const updatePromises = this.selectedItemIds.map(async (item) => {
        // Se a disponibilidade for 3 (requisitar)
        if (item.disponibilidade.id === 3) {
          await api.put(`pacientes/esterilizavel/${item.id}/`, {
            id: item.id,
            ativo: false,
            disponibilidade: 16,
            requisicao: null,
            troca: null,
            retirada: null
          });
          this.atualizar()
        } else  {
            await api.put(`pacientes/esterilizavel/${item.id}/`, {
              id: item.id,
              disponibilidade: 1,
              data_solicitacao_retirada: null,
              data_prevista_solicitacao_retirada: null,
              data_solicitacao_troca: null,
              data_prevista_solicitacao_troca: null,
              requisicao: null,
              troca: null,
              retirada: null
            });
            await api.put(`/equipamentos/esterelizavel_editar/${item.esterilizavel.id}/`, {
                id: item.esterilizavel.id,
                disponibilidade: 1 
              });
            this.atualizar()
        }
      });

      await Promise.all(updatePromises);
      
      this.$toast.success('Esterilizáveis alterados com sucesso!');
      this.atualizar()
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os esterilizáveis. ${error}`);
      } finally {
        this.isLoadingRetirada = false;
        this.selectedItemIds = [];
        // await this.getDisponibilidade();
        this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
        // await this.getEsterilizaveisPaciente();
        this.atualizar()
      }
    },
    async alterarEsterilizaveis() {
      try {
        const promises = this.selectedItemIds.map(item => 
          api.put(`/equipamentos/esterelizavel_editar/${item.esterilizavel.id}/`, {
            id: item.esterilizavel.id,
            disponibilidade: 12 
          })
        );
        await Promise.all(promises);
      } catch (error) {
        throw new Error('Erro ao atualizar itens do paciente: ' + error);
      }
    },
    async initialize() {
       await this.getDisponibilidade()
      this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
      await this.getEsterilizaveisPaciente()
    },
  },
  computed: {
    filteredItems() {
      if (!this.esterilizaveisPaciente) return [];
      
      // Aplica os filtros ativos
      return this.esterilizaveisPaciente.filter(item => {
        // Verifica se existe um filtro ativo para o status
        const activeFilter = this.activeFilters.find(f => f.prop === 'ativo');
        if (activeFilter && activeFilter.value !== undefined) {
          return item.ativo === activeFilter.value;
        }
        return true;
      });
    },
    isRetirarHabilitado() {
      // Verifica se todos os itens selecionados possuem disponibilidade.id === 1
      return this.selectedItemIds.length > 0 && this.selectedItemIds.every(item => {
        return item.disponibilidade?.id === 1; // Verifica a disponibilidade de cada item selecionado
      });
    },
    isCancelar() {
      // Verifica se todos os itens selecionados possuem disponibilidade.id === 1
      return this.selectedItemIds.length > 0 && this.selectedItemIds.every(item => {
        return item.disponibilidade?.id === 3 || item.disponibilidade?.id === 4 || item.disponibilidade?.id === 12; // Verifica a disponibilidade de cada item selecionado
      });
    },
  },
  async mounted() {
    await this.initialize()
  },
}
</script>

<style scoped>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>
